interface IData {
    id: number | string;
    text: string;
    customLabel?: string;
}

export const getTitle = (
    data: IData[],
    activeId: string | number,
    defaultText: string = '',
    key = 'text',
) => {
    const element = data.find(item => item.id === activeId);

    if (!element) {
        return defaultText;
    }

    return element[key];
};
