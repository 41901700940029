import React, { useEffect, FunctionComponent } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import {
    actions as actionsFilters,
    fetchFilters,
} from '@redux/modules/filters';
import { actions as actionsCategories } from '@redux/modules/configs/category';
import { fetchSites, actions as actionsSites } from '@redux/modules/sites';
import { actions as actionsPosition } from '@redux/modules/position';
import { QueryString } from '@tools/queryString';

export interface IPropsWrapper {
    error: boolean;
    bodyPosition: {};
    history: History;
    isMobile: boolean;
    resourceId: number;
    currentPage: number;
    regionIds: number[];
    setFilters: Function;
    projectCount: number;
    clearSites: Function;
    clearFilter: Function;
    updateSites: Function;
    setRegionIds: Function;
    typesData: ITypesData[];
    updateFilters: Function;
    shouldRedirect: boolean;
    showPlaceholder: boolean;
    topLevelRegion: number[];
    defaultPageLimit: number;
    children: FunctionComponent;
    toggleRedirectKey: Function;
    setSubcategoryData: Function;
    activeFilters: IActiveFilters;
    categoryData: ICategoryData[];
    subcategoryData: ICategoryData[];
    filterTypes: { [id: number]: number };
    filterRegions: { [id: number]: number };
    dictRegion: { [id: number]: IRegionType };
    filterCategories: { [id: number]: number };
    dictCategoryData: { [id: number]: ICategoryType };
}

export interface IPropsChildren extends IPropsWrapper {
    onHistory: Function;
    onSubcategoryData: Function;
}

export const Wrapper: React.FC<IPropsWrapper> = (props) => {
    const {
        history,
        children,
        clearSites,
        dictRegion,
        resourceId,
        setFilters,
        clearFilter,
        updateSites,
        setRegionIds,
        bodyPosition,
        activeFilters,
        updateFilters,
        shouldRedirect,
        dictCategoryData,
        toggleRedirectKey,
        setSubcategoryData,
    } = props;

    const onHistory = (
        filters: Record<string, number>,
        options: { clearFilters?: boolean } = {},
    ) => {
        const newFilters = options.clearFilters
            ? filters
            : { ...activeFilters, ...filters };

        const selectedFilters = Object.keys(newFilters).reduce((acc, value) => {
            if (newFilters[value]) {
                return { ...acc, [value]: newFilters[value] };
            }
            return acc;
        }, {});

        const qs = QueryString.getString(selectedFilters);

        history.push({ search: qs });
    };

    const onSubcategoryData = (id) => {
        if (activeFilters.categoryId !== id && !!id) {
            setSubcategoryData(dictCategoryData, id);
        }
    };

    useEffect(() => {
        const qs = QueryString.getString({ ...bodyPosition, resourceId });
        const redirectedUrl = `/navi?${qs}#${resourceId}`;
        const currentUrl = `${history.location.pathname}${history.location.search}${history.location.hash}`;

        if (shouldRedirect && redirectedUrl !== currentUrl) return;

        const parseQs = QueryString.getObject(history.location.search, {
            parseNumbers: true,
        });

        setFilters(parseQs);
        updateSites(parseQs);
        updateFilters(parseQs);
        setRegionIds(parseQs.regionId || 0, dictRegion);
    }, [history.location.search]);

    useEffect(() => {
        if (shouldRedirect) {
            const qs = QueryString.getString({ ...bodyPosition, resourceId });

            history.push(`/navi?${qs}#${resourceId}`);
            toggleRedirectKey(false);
        }
    }, [resourceId]);

    // очищаем редьюсеры, если мы уходим со страницы
    useEffect(() => () => {
        clearFilter(history.location.pathname === '/search');
        clearSites();
    }, []);

    const propsChildren: IPropsChildren = { ...props, onHistory, onSubcategoryData };

    return children(propsChildren);
};

const mapDispatchToProps = dispatch => ({
    setRegionIds: (regionId, data) => (
        dispatch(actionsFilters.SET_REGION_IDS(regionId, data))),
    updateSites: (filters: Record<string, string>) => (
        dispatch(fetchSites(filters))),
    updateFilters: (filters: Record<string, string>) => (
        dispatch(fetchFilters(filters))),
    setFilters: (filters: Record<string, any>) => dispatch(actionsFilters.SET_FILTERS(filters)),
    clearFilter: (
        isSearchPage: boolean = false,
    ) => dispatch(actionsFilters.CLEAR_FILTER(isSearchPage)),
    clearSites: () => dispatch(actionsSites.CLEAR_SITES()),
    toggleRedirectKey: (key: boolean = false) => dispatch(actionsPosition.UPDATE_REDIRECT_KEY(key)),
    setSubcategoryData: (data, id) => dispatch(actionsCategories.SET_SUBCATEGORY_DATA(data, id)),
});

const mapStateToProps = state => ({
    isMobile: state.runtime.isMobile,
    currentPage: state.sites.currentPage,
    defaultPageLimit: state.sites.defaultPageLimit,
    dictCategoryData: state.category.data,
    categoryData: state.category.categoryData,
    subcategoryData: state.category.subcategoryData,
    dictRegion: state.region.data,
    topLevelRegion: state.region.topLevelRegion,
    filterCategories: state.filters.categories,
    filterTypes: state.filters.types,
    filterRegions: state.filters.regions,
    regionIds: state.filters.regionIds,
    activeFilters: state.filters.activeFilters,
    typesData: state.types.data,
    projectCount: state.sites.projectCount,
    error: state.position.error,
    resourceId: state.position.resourceId,
    bodyPosition: state.position.body,
    shouldRedirect: state.position.shouldRedirect,
    showPlaceholder: state.sites.error || state.filters.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
