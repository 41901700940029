import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { BtnIcon } from './BtnIcon';
import { generatePaginationElements } from './generatePaginationElements';

import s from './Pagination.pcss';

interface IPaginationProps {
    showElementBothSide: number;
    defaultLimit: number;
    projectCount: number;
    currentPage: number;
    rootClassName?: string;
    isCenter?: boolean;
    isCircle?: boolean;
    onNextPage?: Function;
    disableDots?: boolean;
    showElementBothSideForMobile?: boolean;
    dataAttributes?: Record<string, string>;
}

export const Pagination: React.FC<IPaginationProps> = ({
    showElementBothSide = 8,
    defaultLimit = 30,
    projectCount = 10,
    currentPage = 1,
    rootClassName = '',
    isCenter = false,
    isCircle = false,
    onNextPage = () => {},
    disableDots = false,
    showElementBothSideForMobile = false,
    dataAttributes = {},
}) => {
    const isMobile = useSelector((state: Record<any, any>) => state.runtime.isMobile);
    const [localCurrentPage, setCurrentPage] = useState(Number(currentPage));

    useEffect(() => {
        setCurrentPage(Number(currentPage));
    }, [currentPage]);

    const handleClick = index => (e) => {
        e.preventDefault();
        setCurrentPage(index);

        window.scrollTo(0, 0);

        onNextPage({ page: index });
    };

    const pageLimit = Math.ceil(projectCount / defaultLimit);

    let showElementsSide = showElementBothSide;

    // На мобильном устройстве необходимо показывать по 2 элемента справа и слева;
    // До 3 страницы элементы справа не должны прибавляться
    if (isMobile && !showElementBothSideForMobile) {
        showElementsSide = 2;
        if (localCurrentPage < 3) {
            showElementsSide = 5 - localCurrentPage;
        }
    }

    const paginationElements = generatePaginationElements(
        showElementsSide,
        projectCount,
        localCurrentPage,
        pageLimit,
    );

    const activePaginationElement = paginationElements.findIndex(
        item => item === localCurrentPage,
    );

    const cnRoot = cn(s.root, rootClassName, {
        [s.isCenter]: isCenter,
        [s.isMobile]: isMobile,
    });

    return (
        <nav className={cnRoot}>
            <BtnIcon
                onClick={handleClick(localCurrentPage - 1)}
                isDisabled={localCurrentPage === 1}
                isPrev
                name="icon-paginator"
                dataAttributes={dataAttributes.name ? {
                    name: dataAttributes.name,
                    value: `${dataAttributes.value}::prew`,
                } : {}}
            />
            {paginationElements.map((item, index) => {
                const cnBtn = cn(s.btn, {
                    [s.isActive]: activePaginationElement === index,
                    [s.isCircle]: isCircle,
                });

                // Если это первый или последний элемент в пагинации и если не мобильная версия
                if (
                    !disableDots
                    && ((index === 0 && localCurrentPage > showElementBothSide)
                    || (index === paginationElements.length - 1
                        && (pageLimit - localCurrentPage) >= showElementBothSide))
                    && !isMobile
                ) {
                    return (
                        <button
                            onClick={handleClick(item)}
                            className={cnBtn}
                            key={item}
                            type="button"
                            {...(dataAttributes.name ? {
                                [dataAttributes.name]: `${dataAttributes.value}::${item}`,
                            } : {})}
                        >
                            ...
                        </button>
                    );
                }

                return (
                    <button
                        onClick={handleClick(item)}
                        className={cnBtn}
                        key={item}
                        type="button"
                        {...(dataAttributes.name ? {
                            [dataAttributes.name]: `${dataAttributes.value}::${item}`,
                        } : {})}
                    >
                        {item}
                    </button>
                );
            })}
            <BtnIcon
                onClick={handleClick(localCurrentPage + 1)}
                isDisabled={localCurrentPage === pageLimit}
                name="icon-paginator"
                dataAttributes={dataAttributes.name ? {
                    name: dataAttributes.name,
                    value: `${dataAttributes.value}::next`,
                } : {}}
            />
        </nav>
    );
};
