import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';

import Ad from '@components/Ad';
import CancelSubscription from '@components/CancelSubscription';
import { layoutAds, IBanner } from '@configs/ad';
import { getAdConfig } from '@tools/ad';

import 'client/css/root.pcss';
import s from './ColumnRight.pcss';

export const ColumnRight: React.FC<RouteComponentProps> = ({
    history: { location: { pathname } },
}) => {
    const isMobile = useSelector(({ runtime }) => runtime.isMobile);

    const errorPosition = useSelector(({ position }) => position.error);
    const errorSites = useSelector(({ sites }) => sites.error);

    const ads: Record<string, IBanner> = getAdConfig(layoutAds, isMobile);

    const cnColumnRight = cn(s.columnRight, {
        [s.innerPages]: pathname !== '/',
        [s.noResult]: errorPosition || errorSites,
        [s.bigMarginTop]: pathname === '/stat',
    });

    return (
        <div className={cnColumnRight}>
            <CancelSubscription />
            <div className={s.banners}>
                <div className={s.bannersItem}>
                    <div className={s.banner}>
                        <Ad canUpdate {...ads.right1} />
                    </div>
                </div>
                <div className={s.bannersItem}>
                    <div className={s.banner}>
                        <Ad canUpdate isLazy {...ads.right2} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ColumnRight);
