import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { History } from 'history';
import Sites, { IPropsChildren } from '@containers/Sites';
import Layout from '@components/Layout';
import { Categories } from '@components/Categories';
import { Filters } from '@components/Filters';
import TableSites from '@containers/TableSites';
import { Pagination } from '@components/Pagination';
import { NoResult } from '@components/NoResult';
import { Notice } from '@components/Notice';
import Announcement from '@components/Announcement';
import { getTitleSeoFragment } from '@tools/getTitleSeoFragment';
import { getDocumentTitle } from '@tools/documentTitle';
import { setMetaTags } from '@tools/setMetaTags';
import { setPageViewMedia } from 'tools/setPageViewMedia';

import s from './Navi.pcss';

interface IProps {
    history: History;
}

const Navi: React.FC<IProps> = ({ history }) => {
    const categoryIds = useSelector((state: Record<any, any>) => state.category);
    const filtersIds = useSelector((state: Record<any, any>) => state.filters);
    const regionData = useSelector(({ region }) => region.data);
    const typesData = useSelector(({ types }) => types.data);

    const titleSeoFragment = getTitleSeoFragment(categoryIds, filtersIds, regionData, typesData);
    const documentTitle = getDocumentTitle(titleSeoFragment);
    const {
        categoryId,
        subcategoryId,
        regionId,
        typeId,
    } = filtersIds.activeFilters;

    useEffect(() => {
        setMetaTags(titleSeoFragment);
        setPageViewMedia(titleSeoFragment, {
            categoryId, subcategoryId, regionId, typeId,
        });

        return () => setMetaTags();
    }, [categoryId, subcategoryId, regionId, typeId]);

    return (
        <DocumentTitle title={documentTitle}>
            <Layout>
                <Sites history={history}>
                    {({
                        error,
                        regionIds,
                        onHistory,
                        dictRegion,
                        setFilters,
                        clearFilter,
                        currentPage,
                        filterTypes,
                        projectCount,
                        setRegionIds,
                        categoryData,
                        activeFilters,
                        filterRegions,
                        topLevelRegion,
                        subcategoryData,
                        showPlaceholder,
                        defaultPageLimit,
                        dictCategoryData,
                        filterCategories,
                        onSubcategoryData,
                    }: IPropsChildren) => (
                        !error && !showPlaceholder ? (
                            <>
                                <div className={s.controls}>
                                    <Announcement />
                                    <Categories
                                        onHistory={onHistory}
                                        filters={activeFilters}
                                        categoryData={categoryData}
                                        onSubcategoryData={onSubcategoryData}
                                    />
                                    <Filters
                                        history={history}
                                        typesData={typesData}
                                        onHistory={onHistory}
                                        regionIds={regionIds}
                                        setFilters={setFilters}
                                        dictRegion={dictRegion}
                                        filterTypes={filterTypes}
                                        clearFilter={clearFilter}
                                        setRegionIds={setRegionIds}
                                        filterRegions={filterRegions}
                                        activeFilters={activeFilters}
                                        topLevelRegion={topLevelRegion}
                                        subcategoryData={subcategoryData}
                                        dictCategoryData={dictCategoryData}
                                        filterCategories={filterCategories}
                                    />
                                </div>
                                <TableSites
                                    onHistory={onHistory}
                                />
                                <Pagination
                                    showElementBothSide={8}
                                    defaultLimit={defaultPageLimit}
                                    projectCount={projectCount}
                                    currentPage={currentPage}
                                    onNextPage={onHistory}
                                />
                            </>
                        ) : (
                            <>
                                <NoResult history={history} />
                                <Notice />
                            </>
                        )
                    )}
                </Sites>
            </Layout>
        </DocumentTitle>
    );
};

export default Navi;
