import React from 'react';
import cn from 'classnames';
import Button from '@components/Button';
import Svg from '@components/Svg';
import openstats from '@constants/openstats';
import { dataAttributesValues, dataAttributesNames } from '@configs/top100counter';
import { eventsTop100 } from '@tools/events';

import s from './AddCounter.pcss';

interface IAddCounter {
    auth?: boolean;
}

const AddCounter: React.FC<IAddCounter> = ({ auth = false }) => {
    const cnButton = cn(s.addCounter, {
        [s.addCounterNoAuth]: !auth,
    });

    const props = auth
        ? {
            children: 'Мои сайты',
            href: 'http://stat.top100.rambler.ru/',
            target: '_blank',
            dataAttributes: {
                name: dataAttributesNames.catalogueNavi,
                value: dataAttributesValues.mySites,
            },
            onClick: () => {
                eventsTop100.mySitesClick();
            },
        } : {
            children: 'Добавить сайт в рейтинг',
            href: 'https://stat.top100.rambler.ru/'
                + `${openstats.name}`
                + `${openstats.service.catalogue}`
                + `${openstats.campaign.sberAds}`
                + `${openstats.ad.addCounter}`
                + `${openstats.source.catalogueHeader}`,
            target: '_blank',
            dataAttributes: {
                name: dataAttributesNames.catalogueNavi,
                value: dataAttributesValues.addSiteToRating,
            },
            onClick: () => {
                eventsTop100.addSiteToRatingClick();
            },
        };

    return (
        <Button
            className={cnButton}
            icon={<Svg name="icon-rating" />}
            iconClassName={s.icon}
            {...props}
        />
    );
};

export default AddCounter;
