import React from 'react';
import { dataAttributesNames, dataAttributesValues } from '@configs/top100counter';
import { eventsYm } from '@tools/events';
import openstats from '@constants/openstats';

import s from './AnaliticsBanner.pcss';


const AnaliticsBanner: React.FC = () => (
    <div className={s.root}>
        <div className={s.inner}>
            <div className={s.content}>
                <div className={s.header}>
                    Гибкая система digital-аналитики
                </div>
                <div className={s.description}>
                    Используйте данные для принятия бизнес-решений,
                    управления контентом и развития ваших площадок
                </div>
                <a
                    className={s.button}
                    href={'https://ads.sber.ru/stat/start'
                        + `${openstats.name}`
                        + `${openstats.service.catalogue}`
                        + `${openstats.campaign.sberAds}`
                        + `${openstats.ad.analiticsBanner}`
                        + `${openstats.source.catalogueHome}`
                    }
                    target="blank"
                    {...{ [dataAttributesNames.promoLanding]: dataAttributesValues.promoLanding }}
                    onClick={() => eventsYm.clickPromoLandingBtn()}
                >
                    Узнать больше
                </a>
            </div>
        </div>
    </div>
);

export default AnaliticsBanner;
