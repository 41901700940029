import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import cn from 'classnames';
import Topline from '@components/Topline';
import SearchForm from '@components/SearchForm';
import AddCounter from '@components/AddCounter';
import Nav from '@components/Nav';
import MainLogo from '@components/MainLogo';
import Ad from '@components/Ad';
import Totals from '@components/Totals';

import { layoutAds, IBanner } from '@configs/ad';
import { getAdConfig } from '@tools/ad';

import s from './Header.pcss';

interface IProps {
    pathname: string;
    isMobile: boolean;
}

const Header: React.FC<IProps> = ({
    isMobile,
    pathname,
}) => {
    const isAuth = useSelector(({ user }) => user.isAuth);
    const { siteCount = 0, yesterdayViews = 0 } = useSelector(({ totals }) => totals);
    const [containerWidth, setContainerWidth] = useState(0);
    const headerRef = useRef(null);

    const ads: Record<string, IBanner> = getAdConfig(layoutAds, isMobile);

    const onResize = debounce(() => {
        if (headerRef && headerRef.current) {
            setContainerWidth(headerRef.current.getBoundingClientRect().width);
        }
    });

    useEffect(() => {
        if (headerRef && headerRef.current) {
            setContainerWidth(headerRef.current.getBoundingClientRect().width);
        }
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [setContainerWidth, onResize]);

    const cnHeader = cn(s.header, {
        [s.innerHeader]: pathname !== '/',
    });

    const cnRow = cn(s.row, {
        [s.hideMobileContent]: isMobile,
    });

    const cnNavTotals = cn(s.row, {
        [s.hideMobileContent]: pathname !== '/',
    });

    return (
        <>
            <header className={cnHeader} ref={headerRef}>
                <Topline isMobile={isMobile} />
                <Ad
                    showSkeleton
                    ignoreBackgroundPrime
                    className={s.ad}
                    canUpdate
                    {...ads.top}
                />
                <div className={s.container}>
                    <div className={cnRow}>
                        <MainLogo className={s.logo} />
                        {!isMobile && (<SearchForm className={s.form} />)}
                        <AddCounter auth={isAuth} />
                    </div>
                    <div className={cnNavTotals}>
                        <Nav
                            className={s.nav}
                            pathname={pathname}
                            containerWidth={containerWidth}
                            isAuth={isAuth}
                        />

                        <Totals
                            isMobile={isMobile}
                            className={s.totals}
                            siteCount={siteCount}
                            yesterdayViews={yesterdayViews}
                        />
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
