import React from 'react';
import s from './Announcement.pcss';

const Announcement = () => (
    <div className={s.root}>
        Мы обновили расчет метрики &quot;Отказ&rdquo;.
        Ранее отказом считался любой визит только с одним просмотром без учета длины визита,
        сейчас же отказом считается визит с одним просмотром и длиною до 15 секунд.
        Из-за этого расчет показателя Популярность также изменился,
        поэтому места в Рейтинге распределяются иначе.
    </div>
);

export default Announcement;
