import openstats from '@constants/openstats';
import { dataAttributesValues, dataAttributesNames } from '@configs/top100counter';
import { eventsYm, eventsTop100 } from '@tools/events';

export const navItems = [
    {
        text: 'Каталог сайтов',
        url: '/'
            + `${openstats.name}`
            + `${openstats.service.catalogue}`
            + `${openstats.campaign.sberAds}`
            + `${openstats.ad.navButtonCatalogue}`
            + `${openstats.source.catalogueHeader}`,
        innerPage: true,
        dataAttr: { [dataAttributesNames.catalogueNavi]: dataAttributesValues.siteCatalogueNavi },
        onClick: () => {
            eventsYm.siteCatalogueClick();
            eventsTop100.siteCatalogueClickDemo();
        },
    },
    {
        text: 'Рейтинг сайтов',
        url: '/navi/'
            + `${openstats.name}`
            + `${openstats.service.catalogue}`
            + `${openstats.campaign.sberAds}`
            + `${openstats.ad.navButtonRating}`
            + `${openstats.source.catalogueHeader}`,
        innerPage: true,
        dataAttr: { [dataAttributesNames.catalogueNavi]: dataAttributesValues.ratingNavi },
        onClick: () => {
            eventsYm.ratingClick();
            eventsTop100.ratingClickDemo();
        },
    },
    {
        text: 'Аналитика для сайта',
        url: 'https://stat.top100.rambler.ru'
            + `${openstats.name}`
            + `${openstats.service.catalogue}`
            + `${openstats.campaign.sberAds}`
            + `${openstats.ad.navButtonAnalytics}`
            + `${openstats.source.catalogueHeader}`,
        notAuthUrl: 'https://ads.sber.ru/stat/start/'
            + `${openstats.name}`
            + `${openstats.service.catalogue}`
            + `${openstats.campaign.sberAds}`
            + `${openstats.ad.navButtonStart}`
            + `${openstats.source.catalogueHeader}`,
        innerPage: false,
        dataAttr: { [dataAttributesNames.catalogueNavi]: dataAttributesValues.analyticsNavi },
        onClick: () => {
            eventsYm.analyticsClick();
            eventsTop100.analyticsClickDemo();
        },
    },
    {
        text: 'Демо Аналитики',
        url: 'https://stat.top100.rambler.ru/projects/7596044'
        + `${openstats.name}`
        + `${openstats.service.catalogue}`
        + `${openstats.campaign.sberAds}`
        + `${openstats.ad.navButtonDemo}`
        + `${openstats.source.catalogueHeader}`,
        innerPage: false,
        dataAttr: { [dataAttributesNames.catalogueNavi]: dataAttributesValues.demoNavi },
        onClick: () => {
            eventsYm.demoClick();
            eventsTop100.demoClickDemo();
        },
    },
];
