import React, { useState, useEffect } from 'react';
import last from 'lodash/last';
import cn from 'classnames';
import { History } from 'history';
import { RegionsDesktop } from '@components/Regions';
import { DropdownDesktop } from '@components/Dropdown';
import List from '@components/List';
import Select from '@components/List/Select';
import { getTitle } from '@tools/getTitle';
import { defaultTexts } from '@constants/defaultTexts';

import { rangeData } from './configs';

import { Item } from './Item';

import s from './Filters.pcss';

interface IDesktopFiltersProps {
    history: History;
    onHistory: Function;
    regionIds: number[];
    clearFilter: Function;
    setRegionIds: Function;
    typesData: ITypesData[];
    topLevelRegion: number[];
    activeFilters: IActiveFilters;
    subcategoryData: ICategoryData[];
    filterTypes: { [id: number]: number };
    filterRegions: { [id: number]: number };
    dictRegion: { [id: number]: IRegionType };
    filterCategories: { [id: number]: number };
}

export const DesktopFilters: React.FC<IDesktopFiltersProps> = ({
    history,
    onHistory,
    regionIds,
    typesData,
    dictRegion,
    clearFilter,
    filterTypes,
    setRegionIds,
    activeFilters,
    filterRegions,
    topLevelRegion,
    subcategoryData,
    filterCategories,
}) => {
    const [isOpenedSubcategory, setIsOpenedSubcategory] = useState(!!activeFilters.isCatOpen);
    const [isOpenedType, setIsOpenedType] = useState(false);
    const [isOpenedRegion, setIsOpenedRegion] = useState(false);
    const [isOpenedRange, setIsOpenedRange] = useState(false);
    const [showFilterSubcategory, setShowFilterSubcategory] = useState(!!subcategoryData.length);

    useEffect(() => {
        setIsOpenedSubcategory(!!activeFilters.isCatOpen);
    }, [activeFilters.isCatOpen]);

    useEffect(() => {
        setShowFilterSubcategory(!!activeFilters.categoryId);
    }, [activeFilters.categoryId]);

    const handleClear = () => {
        clearFilter();
        setRegionIds(0, {});
        setShowFilterSubcategory(false);

        history.push({ search: '' });
    };

    return (
        <div className={s.filters}>
            <div className={s.filtersGroup}>
                {showFilterSubcategory && (
                    <DropdownDesktop
                        isOpened={isOpenedSubcategory}
                        className={s.filtersDropdown}
                        anchor={({ onToggle }) => (
                            <Item
                                title={getTitle(
                                    subcategoryData,
                                    activeFilters.subcategoryId,
                                    defaultTexts.subtopics,
                                )}
                                onClick={onToggle}
                                filterName="subcategory"
                            />
                        )}
                        onOpen={() => setIsOpenedSubcategory(true)}
                        onClose={() => setIsOpenedSubcategory(false)}
                    >
                        <List
                            data={subcategoryData}
                            countsMap={filterCategories}
                            activeId={activeFilters.subcategoryId}
                            onClickItem={(id) => {
                                onHistory({
                                    subcategoryId: id,
                                    isCatOpen: 0,
                                    page: 0,
                                });
                                setIsOpenedSubcategory(false);
                            }}
                            itemTypes="subcategory"
                        />
                    </DropdownDesktop>
                )}
                <DropdownDesktop
                    isOpened={isOpenedType}
                    className={s.filtersDropdown}
                    anchor={({ onToggle }) => (
                        <Item
                            title={getTitle(
                                typesData,
                                activeFilters.typeId,
                                defaultTexts.types,
                            )}
                            onClick={onToggle}
                            filterName="types"
                        />
                    )}
                    onOpen={() => setIsOpenedType(true)}
                    onClose={() => setIsOpenedType(false)}
                >
                    <List
                        data={typesData}
                        countsMap={filterTypes}
                        activeId={activeFilters.typeId}
                        onClickItem={(id) => {
                            onHistory({
                                typeId: id,
                                page: 0,
                            });
                            setIsOpenedType(false);
                        }}
                        itemTypes="type"
                    />
                </DropdownDesktop>
                <DropdownDesktop
                    noPaddingContent
                    isOpened={isOpenedRegion}
                    className={s.filtersDropdown}
                    anchor={({ onToggle }) => {
                        const { regionId } = activeFilters;
                        return (
                            <Item
                                title={
                                    dictRegion[regionId]
                                        ? dictRegion[regionId].title
                                        : defaultTexts.regions
                                }
                                onClick={onToggle}
                                filterName="regions"
                            />
                        );
                    }}
                    onOpen={() => setIsOpenedRegion(true)}
                    onClose={() => setIsOpenedRegion(false)}
                >
                    <RegionsDesktop
                        dict={dictRegion}
                        selected={regionIds}
                        countsMap={filterRegions}
                        firstItems={topLevelRegion}
                        onApply={(ids) => {
                            onHistory({
                                regionId: last(ids),
                                page: 0,
                            });
                            setRegionIds(ids, dictRegion);
                            setIsOpenedRegion(false);
                        }}
                        onCancel={() => setIsOpenedRegion(false)}
                    />
                </DropdownDesktop>
                <Item
                    noArrow
                    title="Сбросить"
                    onClick={handleClear}
                    className={s.filtersDropdown}
                    filterName="clear"
                />
            </div>
            <div className={cn(s.filtersGroup, s.right)}>
                <DropdownDesktop
                    noPaddingContent
                    isOpened={isOpenedRange}
                    className={s.filtersDropdown}
                    anchor={props => (
                        <Item
                            title={getTitle(
                                rangeData,
                                activeFilters.period,
                                'За день',
                                'customLabel',
                            )}
                            onClick={props.onToggle}
                            filterName="period"
                        />
                    )}
                    onOpen={() => setIsOpenedRange(true)}
                    onClose={() => setIsOpenedRange(false)}
                >
                    <Select
                        data={rangeData}
                        activeId={activeFilters.period}
                        onClickItem={(id) => {
                            setIsOpenedRange(false);
                            onHistory({
                                period: id,
                                page: 0,
                            });
                        }}
                        itemTypes="period"
                    />
                </DropdownDesktop>
            </div>
        </div>
    );
};
