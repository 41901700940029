import { IState as IStateFilters } from '@redux/modules/filters';
import { IState as IStateCategories } from '@redux/modules/configs/category';

import {
    normalizeData,
    normalizeTypes,
    normalizeSubcategories,
} from '@tools/normalizeData';

export const getCategorySeoTitle = (
    categoryTitle: string,
    subCategoryData: { [id: number]: ICategoryType },
    filters: IStateFilters,
): string => {
    let categoryName = `${categoryTitle}`;

    const subCategoriesData = normalizeSubcategories(
        subCategoryData, filters.activeFilters.categoryId,
    );

    const subCategoryItem = subCategoriesData.find(
        item => item.id === filters.activeFilters.subcategoryId,
    );

    if (subCategoryItem && subCategoryItem.id !== 0) {
        categoryName = `${categoryName} / ${subCategoryItem.text}`;
    }

    return categoryName;
};

export const getRegionSeoTitle = (
    regionId: number,
    regionDict: { [id: number]: IRegionType },
): string => (regionDict[regionId] ? `${regionDict[regionId].title}` : '');

export const getTypeSeoTitle = (
    typeId: number,
    typesData: ITypes[],
): string => {
    const typesDataPrepared = normalizeTypes(typesData);
    const typeItem = typesDataPrepared.find(item => item.id === typeId);

    return typeItem ? `${typeItem.text}` : '';
};

/**
 * Получение текущего meta (seo) фрагмента заголовка по активному элементу в фильтрах каталога:
 * Добавляем активную категорию или категорию с подкатегорией, либо активный регион | тип
 * Если выбрана только корневая категория (id=0), то берем ее название
 */
export const getTitleSeoFragment = (
    category: IStateCategories,
    filters: IStateFilters,
    regionData: { [id: number]: IRegionType },
    typesData: ITypes[],
): string => {
    const categoryDataPrepared = normalizeData(category.allTopicIds, category.data);
    const categoryItem = categoryDataPrepared.find(
        item => item.id === filters.activeFilters.categoryId,
    );
    let seoTitle;

    if (categoryItem && categoryItem.id !== 0) {
        seoTitle = getCategorySeoTitle(categoryItem.text, category.data, filters);
    } else if (filters.activeFilters.regionId && filters.activeFilters.regionId !== 0) {
        seoTitle = getRegionSeoTitle(filters.activeFilters.regionId, regionData);
    } else if (filters.activeFilters.typeId) {
        seoTitle = getTypeSeoTitle(filters.activeFilters.typeId, typesData);
    } else {
        seoTitle = categoryItem ? `${categoryItem.text}` : '';
    }

    return seoTitle;
};
