import React, { ReactNode, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import cn from 'classnames';

import Ad from '@components/Ad';
import Footer from '@components/Footer';
import Header from '@components/Header';
import ColumnRight from '@components/ColumnRight';
import { SnackbarNotification } from '@components/SnackbarNotification';
import { setSnackbarAction, clearSnackbarAction } from '@redux/modules/global';
import SnackbarConfig from '@configs/snackbar';

import { layoutAds, IBanner } from '@configs/ad';
import { getAdConfig } from '@tools/ad';
import { eventsTop100 } from '@tools/events';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { checkAdBlock } from '@hydra/top100-components/helpers';

import 'client/css/root.pcss';
import s from './Layout.pcss';

interface ILayoutProps {
    noRightColumn?: boolean;
    children: ReactNode;
    history?: History;
}

const Layout: React.FC<ILayoutProps & RouteComponentProps> = ({
    noRightColumn = false,
    children,
    history: {
        location: { pathname },
    },
}) => {
    const dispatch = useDispatch();
    const isMobile = useSelector(({ runtime }) => runtime.isMobile);
    const showSnackbar = useSelector((state: Record<any, any>) => state.global.snackbar) !== null;

    const cnColumnLeft = cn(s.columnLeft, {
        [s.fullWidth]: noRightColumn,
    });

    const ads: Record<string, IBanner> = getAdConfig(layoutAds, isMobile);
    const [isCloseByUser, setCloseByUserLocalStorage] = useLocalStorage<true | false>('adblock-close', false);

    useEffect(() => {
        const onClose = () => {
            setCloseByUserLocalStorage(true);
            dispatch(clearSnackbarAction());
        };

        const getADBlock = async () => {
            const hasAdblock: boolean = await checkAdBlock();

            eventsTop100.sendCVAdblock(hasAdblock);
            eventsTop100.sendCVDemoAdblock(hasAdblock);

            if (!isCloseByUser && hasAdblock) {
                dispatch(setSnackbarAction({
                    ...SnackbarConfig.ADBLOCK,
                    onClose,
                }));
            }
        };

        getADBlock();
    }, []); // eslint-disable-line

    return (
        <div className={s.root}>
            <Header isMobile={isMobile} pathname={pathname} />

            <main className={s.content}>
                <div className={s.container}>
                    <div className={cnColumnLeft}>
                        {children}

                        {(
                            <Ad canUpdate isLazy {...ads.bottom} />
                        )}
                    </div>

                    {!isMobile && !noRightColumn && (
                        <ColumnRight />
                    )}
                </div>
                {showSnackbar && <SnackbarNotification />}
            </main>

            <Footer isMobile={isMobile} />
        </div>
    );
};

export default withRouter(Layout);
