import React from 'react';
import Button from '@components/Button';
import { History } from 'history';

import s from './NoResult.pcss';

interface IProps {
    history: History;
}

export const NoResult: React.FC<IProps> = ({ history }) => {
    const handleClick = () => history.goBack();

    return (
        <div className={s.root}>
            <h2 className={s.title}>Ничего не найдено</h2>
            <p className={s.desc}>
                Возможно, сайт, который вы ищете,
                не участвует в рейтинге или находится на модерации.
            </p>
            <Button
                className={s.btn}
                noBordered
                children="Вернуться назад"
                onClick={handleClick}
            />
        </div>
    );
};
