import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Svg from '@components/Svg';

import { MOBILE_WIDTH } from '@constants/global';

import { navItems } from './navItems';

import s from './Nav.pcss';

const TABLET_DOWN = 800;
const COUNT_MENU_ON_TABLET = 2;

interface IProps {
    pathname: string;
    className: string;
    containerWidth: number;
    isAuth: boolean;
}

const renderMenuItem = (menuItems, pathname, isAuth) => menuItems.map(
    (navItem) => {
        const cnLink = cn(s.link, {
            [s.isActive]: pathname === navItem.url,
        });
        const cnIcon = cn(s.icon, {
            [s.isActive]: pathname === navItem.url,
        });

        const text = (
            <>
                {navItem.icon && (
                    <Svg name={navItem.icon} className={cnIcon} />
                )}
                {navItem.text}
            </>
        );

        const onClick = () => {
            if (typeof navItem.onClick === 'function') {
                navItem.onClick();
            }
        };

        if (navItem.innerPage) {
            return (
                <Link
                    key={navItem.url}
                    className={cnLink}
                    to={navItem.url}
                    children={text}
                    onClick={onClick}
                    {...(navItem.dataAttr ? navItem.dataAttr : {})}
                />
            );
        }

        const url = !isAuth && navItem.notAuthUrl ? navItem.notAuthUrl : navItem.url;

        return (
            <a
                key={url}
                href={url}
                className={cnLink}
                target="_blank"
                rel="noopener nofollow"
                onClick={onClick}
                {...(navItem.dataAttr ? navItem.dataAttr : {})}
            >
                {text}
            </a>
        );
    },
);

const getCountMenu = (width: number, arrMenuLen: number) => {
    if (width >= MOBILE_WIDTH && width <= TABLET_DOWN) {
        return COUNT_MENU_ON_TABLET;
    }

    return arrMenuLen;
};

const Nav: React.FC<IProps> = ({
    containerWidth,
    pathname,
    className,
    isAuth,
}) => {
    const [toggleDropdown, setOpenDropdown] = useState(false);
    const [prevPathname, setPrevPathName] = useState(pathname);
    const moreRef = useRef(null);
    const countMenu = getCountMenu(containerWidth, navItems.length);

    if (prevPathname !== pathname) {
        setPrevPathName(pathname);
        setOpenDropdown(false);
    }

    const handleToggleDropdown = toggle => (event) => {
        event.preventDefault();
        setOpenDropdown(toggle);
    };

    const handleToggleDropdownOut = (event) => {
        if (moreRef.current && moreRef.current.contains(event.target)) {
            return;
        }

        setOpenDropdown(false);
    };

    useEffect(() => {
        window.addEventListener('mousedown', handleToggleDropdownOut);

        return () => {
            window.removeEventListener('mousedown', handleToggleDropdownOut);
        };
    });

    const cnDropdown = cn(s.dropdown, {
        [s.dropdownVisible]: toggleDropdown,
    });

    return (
        <>
            <nav className={cn(s.root, className)}>
                {renderMenuItem(navItems.slice(0, countMenu), pathname, isAuth)}
            </nav>
            {countMenu === COUNT_MENU_ON_TABLET && (
                <div className={s.more} ref={moreRef}>
                    <button
                        onClick={handleToggleDropdown(!toggleDropdown)}
                        className={s.link}
                        type="button"
                    >
                        Ещё
                    </button>
                    <div className={cnDropdown}>
                        <Svg
                            onClick={handleToggleDropdown(false)}
                            className={s.dropdownIcon}
                            name="icon-arrow-up-small"
                        />
                        {renderMenuItem(navItems.slice(countMenu), pathname, isAuth)}
                    </div>
                </div>
            )}
        </>
    );
};

export default Nav;
