// генерация массива элементов пагинации, которые должны быть отображены
export const generatePaginationElements = (
    showElementBothSide: number,
    projectCount: number,
    currentPage: number,
    pageLimit: number,
) => {
    const elements = [];

    // Первый элемент в пагинации
    let start = currentPage - (currentPage - 1);
    // Последний элемент в пагинации
    let end = currentPage + showElementBothSide;

    if (currentPage >= (showElementBothSide + 2)) {
        start = currentPage - showElementBothSide;
    }

    if ((pageLimit - currentPage) < showElementBothSide) {
        end = currentPage + (pageLimit - currentPage);
    }

    for (let index = start; index <= end; index += 1) {
        elements.push(index);
    }

    return elements;
};
