import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Snackbar } from 'rambler-ui/Snackbar';
import { clearSnackbarAction } from '@redux/modules/global';

import cn from 'classnames';
import s from './SnackbarNotification.pcss';

export const SnackbarNotification: React.FC = () => {
    const {
        message = '',
        showClose = false,
        delay = 3000,
        positionY = 'top',
        positionX = 'right',
        size = 'small',
        type = 'main',
        className = '',
        onClose = null,
    } = useSelector((state: Record<any, any>) => state.global.snackbar);

    const dispatch = useDispatch();

    const clearSnackbar = useCallback(() => {
        dispatch(clearSnackbarAction());
    }, [dispatch]);

    const [snackbarTimeout, setSnackbarTimeout] = useState(null);

    const handleClearSnackbar = useCallback(() => {
        clearTimeout(snackbarTimeout);
        setSnackbarTimeout(null);
        clearSnackbar();
    }, [snackbarTimeout, setSnackbarTimeout, clearSnackbar]);

    useEffect(() => {
        if (message) {
            if (snackbarTimeout !== null) {
                handleClearSnackbar();
            }

            if (delay !== 0) {
                const timeout = setTimeout(() => {
                    clearSnackbar();
                }, delay);

                setSnackbarTimeout(timeout);
            }
        }
    }, [message, clearSnackbar, delay, handleClearSnackbar, snackbarTimeout]);

    const handleCloseSnackbar = () => {
        if (snackbarTimeout !== null) {
            handleClearSnackbar();
        }

        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const snackbarIsOpen = Boolean(message);

    // eslint-disable-next-line react/no-danger
    const createHTML = () => <span dangerouslySetInnerHTML={{ __html: message }} />;

    return (
        <div className={s.snackbar}>
            <Snackbar
                className={cn(s.snackbar, s[className])}
                positionY={positionY}
                positionX={positionX}
                size={size}
                type={type}
                showClose={showClose}
                isOpened={snackbarIsOpen}
                autoCloseDuration={delay}
                onRequestClose={handleCloseSnackbar}
            >
                { createHTML() }
            </Snackbar>
        </div>
    );
};
