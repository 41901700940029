import React from 'react';
import cn from 'classnames';
import Svg from '@components/Svg';

import s from './Select.pcss';

export interface ISelectItem {
    readonly id: number | string;
    readonly url?: string;
    readonly text: string;
    readonly count?: number;
}

interface ISelectProps {
    data: ISelectItem[];
    activeId?: number | string;
    rootClassName?: string;
    selectClassName?: string;
    selectLabelClassName?: string;
    onChange?: Function;
    iconName?: string;
    iconSize?: 'normal' | 'big';
    alignLabel?: 'center' | 'right';
    noShadow?: boolean;
    dataAttributes?: Record<string, string>;
}

export const Select: React.FC<ISelectProps> = ({
    data,
    onChange = () => {},
    activeId,
    rootClassName = '',
    selectClassName = '',
    selectLabelClassName = '',
    iconName = 'icon-triangle',
    iconSize = 'normal',
    alignLabel = '',
    noShadow = false,
    dataAttributes = {},
}) => {
    const renderItem = (item: ISelectItem, key: number) => {
        const attributesNames = String(item.id).split(',')
            .map(currItem => `::${currItem}`).join('');
        const dataAttributesValues = dataAttributes.name ? `${dataAttributes.value}${attributesNames}` : '';
        return (
            <option
                key={key}
                value={item.id}
                {...(dataAttributes.name ? { [dataAttributes.name]: dataAttributesValues } : {})}
            >
                {item.text}
            </option>
        );
    };

    const handleChangeValue = e => onChange(e.target.value);

    const iconClassNames = cn(s.selectIcon, {
        [s.big]: iconSize === 'big',
    });
    const rootClassNames = cn(s.root, rootClassName, { [s.noShadow]: noShadow });
    const selectClassNames = cn(s.select, selectClassName);
    const selectLabelClassNames = cn(s.selectLabel, selectLabelClassName, {
        [s.right]: alignLabel === 'right',
        [s.center]: alignLabel === 'center',
    });

    const findItem = data.find(i => i.id === activeId);

    const label = findItem ? findItem.text : '';

    return (
        <div className={rootClassNames}>
            <div className={selectClassNames}>
                <div className={selectLabelClassNames}>{label}</div>
                <div className={iconClassNames}>
                    <Svg name={iconName} />
                </div>
            </div>
            <select
                className={s.native}
                onChange={handleChangeValue}
                value={activeId}
                {...(dataAttributes.name ? { [dataAttributes.name]: dataAttributes.value } : {})}
            >
                {data.map(renderItem)}
            </select>
        </div>
    );
};
