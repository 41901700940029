import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './Table.pcss';

interface ITableProps {
    className?: string;
    children: ReactNode;
}

const Root: React.FC<ITableProps> = ({ className, children }) => (
    <table className={cn(s.root, className)}>
        {children}
    </table>
);

export default Root;
