export class Formatter {
    static numberWithSpaces(number: number | string) {
        if (!number) {
            return '0';
        }

        const tmp = number.toString().split('.');
        tmp[0] = tmp[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        return tmp.join('.');
    }
}
