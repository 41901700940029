/**
 * TODO - Добавить типы и описание
 */
export const plural = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};

/**
 * Получение хеша по стройке
 * @param s
 */
export const getHash = (s: string): string => {
    let a = 0;
    let c = 0;
    let o = null;

    if (!s) return '0';

    for (let h = s.length - 1; h >= 0; h--) {
        o = s.charCodeAt(h);
        a = (a << 6 & 268435455) + o + (o << 14);
        c = a & 266338304;
        a = c !== 0 ? a ^ c >> 21 : a;
    }

    return String(a);
};
