import React, { useEffect, useState, ReactNode } from 'react';

import s from './Modal.pcss';

interface IModalProps {
    isOpened: boolean;
    toggleOpenModal: (isOpen: boolean) => void;
    children: ReactNode;
}

const ESCAPE_KEY_CODE = 27;

export const Modal: React.FC<IModalProps> = ({ isOpened, children, toggleOpenModal }) => {
    const [stateIsOpen, setIsOpen] = useState(isOpened);

    // Консистентность пропса и стейта
    if (stateIsOpen !== isOpened) {
        setIsOpen(isOpened);
    }

    const handleModal = isOpen => (e) => {
        // Если клик произошёл по Overlay или была нажата клавиша ESC
        // то модальное окно закроется
        if (
            (e.target.id !== 'modal' && e.type === 'click')
            || e.keyCode === ESCAPE_KEY_CODE
        ) {
            setIsOpen(false);
            toggleOpenModal(isOpen);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleModal(false));

        return () => {
            window.removeEventListener('keydown', handleModal(false));
        };
    });

    return stateIsOpen ? (
        <>
            <div
                onClick={handleModal(false)}
                onKeyDown={handleModal(false)}
                className={s.overlay}
            />
            <div className={s.modal} role="dialog" id="modal">
                {children}
            </div>
        </>
    ) : null;
};
