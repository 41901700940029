import React from 'react';
import { useSelector } from 'react-redux';
import { History } from 'history';

import { MobileFilters } from './Mobile';
import { DesktopFilters } from './Desktop';
import { DisplayMobileFilters } from './DisplayMobileFilters';

export interface IFiltersProps {
    history: History;
    regionIds: number[];
    onHistory: Function;
    setFilters: Function;
    clearFilter: Function;
    setRegionIds: Function;
    typesData: ITypesData[];
    topLevelRegion: number[];
    activeFilters: IActiveFilters;
    subcategoryData: ICategoryData[];
    filterTypes: { [id: number]: number };
    filterRegions: { [id: number]: number };
    dictRegion: { [id: number]: IRegionType };
    filterCategories: { [id: number]: number };
    dictCategoryData: { [id: number]: ICategoryType };
}

export const Filters: React.FC<IFiltersProps> = ({
    history,
    regionIds,
    typesData,
    onHistory,
    dictRegion,
    clearFilter,
    filterTypes,
    setRegionIds,
    filterRegions,
    activeFilters,
    topLevelRegion,
    subcategoryData,
    dictCategoryData,
    filterCategories,
}) => {
    const isMobile = useSelector(({ runtime }) => runtime.isMobile);

    return isMobile ? (
        <>
            <DisplayMobileFilters
                onHistory={onHistory}
                typesData={typesData}
                filters={activeFilters}
                regionData={dictRegion}
                setRegionIds={setRegionIds}
                categoryData={dictCategoryData}
            />
            <MobileFilters
                typesData={typesData}
                regionIds={regionIds}
                onHistory={onHistory}
                dictRegion={dictRegion}
                setRegionIds={setRegionIds}
                activeFilters={activeFilters}
                topLevelRegion={topLevelRegion}
                subcategoryData={subcategoryData}
            />
        </>
    ) : (
        <DesktopFilters
            history={history}
            onHistory={onHistory}
            typesData={typesData}
            regionIds={regionIds}
            dictRegion={dictRegion}
            clearFilter={clearFilter}
            filterTypes={filterTypes}
            setRegionIds={setRegionIds}
            filterRegions={filterRegions}
            activeFilters={activeFilters}
            subcategoryData={subcategoryData}
            topLevelRegion={topLevelRegion}
            filterCategories={filterCategories}
        />
    );
};
