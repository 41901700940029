import React, { Children } from 'react';
import cn from 'classnames';

import s from './Grid.pcss';

interface IColumns {
    size: string;
    className?: string;
}

interface IGridProps {
    children: React.ReactNode;
    paramsColumns?: IColumns[];
    className?: string;
}

const Grid: React.FC<IGridProps> = ({ className = '', paramsColumns = [], children }) => {
    if (paramsColumns.length <= 1) {
        return (
            <div className={cn(s.column, className)}>
                {children}
            </div>
        );
    }

    return (
        <div className={cn(s.grid, className)}>
            {
                Children.map(children, (child, index) => {
                    const count = index % paramsColumns.length;
                    const cnColumn = cn(s.column, paramsColumns[count].className, s[`w-${paramsColumns[count].size}`]);
                    return (
                        <div className={cnColumn}>
                            {child}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Grid;
