import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './Table.pcss';

interface IWrapperProps {
    className?: string;
    children: ReactNode;
    isDifferentColors?: boolean;
    selected?: boolean;
}

const Row: React.FC<IWrapperProps> = ({
    className,
    children,
    isDifferentColors = false,
    selected = false,
}) => {
    const cnRow = cn({
        [s.row]: isDifferentColors,
        [s.rowSelected]: selected,
    }, className);

    return (
        <tr className={cnRow}>
            {children}
        </tr>
    );
};

export default Row;
