import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './Table.pcss';

interface IWrapperProps {
    className?: string;
    tag?: 'td' | 'th';
    textAlign?: 'left' | 'right';
    verticalAlign?: 'top' | 'middle';
    hideMobile?: boolean;
    onlyMobile?: boolean;
    colSpan?: number;
    children?: ReactNode;
    onClick?: Function;
}

const Cell: React.FC<IWrapperProps> = ({
    tag = 'td',
    textAlign = 'left',
    verticalAlign = 'top',
    className,
    children,
    colSpan,
    onClick = () => {},
    hideMobile = false,
    onlyMobile = false,
}) => {
    const props = {
        onClick,
        colSpan,
        className: cn(
            s.cell,
            className,
            s[tag],
            s[`${textAlign}Align`],
            s[`${verticalAlign}VerticalAlign`],
            {
                [s.hideMobile]: hideMobile,
                [s.onlyMobile]: onlyMobile,
            },
        ),
    };

    return React.createElement(tag, props, children);
};

export default Cell;
