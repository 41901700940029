import React from 'react';
import Svg from '@components/Svg';

import s from './Favicon.pcss';

export const Favicon = ({ favicon }) => (
    <span className={s.root}>
        {favicon ? (
            <img src={`data:image/png;base64,${favicon}`} alt="" />
        ) : (
            <Svg className={s.icon} name="icon-no-favicon" />
        )}
    </span>
);
