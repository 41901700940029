export const dataAttributesNames = {
    promoLanding: 'data-top100promo',
    catalogueNavi: 'data-catalogue-navi',
};

export const dataAttributesValues = {
    sourceButton: 'source::button',
    sourceForm: 'source::form',
    category: 'category',
    details: 'details',
    pagination: 'pagination',
    promoLanding: 'sites_catalogue::banner_885x180',
    siteCatalogueNavi: 'site_catalogue',
    analyticsNavi: 'analytics',
    ratingNavi: 'rating',
    demoNavi: 'demo',
    addSiteToRating: 'add_site_to_rating',
    mySites: 'my_sites',
};
