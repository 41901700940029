import React from 'react';
import { Link } from 'react-router-dom';

import s from './ShowMore.pcss';

interface IShowMoreProps {
    url: string;
}

const ShowMore: React.FC<IShowMoreProps> = ({ url }) => (
    <Link
        className={s.root}
        to={url}
        children="Показать всё"
        data-category="all_popular"
        data-atst="link-showMore"
    />
);

ShowMore.defaultProps = {
    url: '',
};

export default ShowMore;
