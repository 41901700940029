import React, { MouseEvent } from 'react';
import cn from 'classnames';

import s from './Filters.pcss';

interface IFiltersItemProps {
    title: string;
    noArrow?: boolean;
    className?: string;
    onClick: (event: MouseEvent) => void;
    filterName?: string;
}

/**
 * Компонент элемента фильтра
 */
export const Item: React.FC<IFiltersItemProps> = ({
    className = '',
    title = '',
    onClick = () => {},
    noArrow = false,
    filterName = '',
}) => (
    <div
        className={cn(s.filtersItem, className)}
        onClick={onClick}
        role="presentation"
        data-atst={`filterLabel-${filterName}`}
    >
        <div className={cn(s.filtersTitle, { [s.noArrow]: noArrow })}>
            <div className={s.filtersText}>{title}</div>
        </div>
    </div>
);
