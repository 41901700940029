export default {
    name: '?_openstat=',
    service: {
        catalogue: 'catalogue_top100;',
    },
    campaign: {
        sberAds: 'sberAds;',
    },
    ad: {
        logoTop100: 'logo_top100;',
        addCounter: 'add_counter_button;',
        navButtonCatalogue: 'catalogue_nav_button;',
        navButtonAnalytics: 'analytics_nav_button;',
        navButtonStart: 'analytics_start_nav_button;',
        navButtonDemo: 'demo_nav_button',
        navButtonRating: 'rating_nav_button;',
        analiticsBanner: 'analitics_banner;',
        ratingButtonAllThemes: 'rating_all_themes_button;',
    },
    source: {
        catalogueHome: 'catalogue_home',
        catalogueHeader: 'catalogue_header',
    },
};
