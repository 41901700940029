import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './Tabs.pcss';

interface ITabProps {
    titleTab: string;
    children: ReactNode;
    showMore?: ReactNode;
    isActive?: boolean;
    onClick?: (number: number) => void;
    tabIndex?: number;
    name?: string;
}

const Tab: React.FC<ITabProps> = ({
    titleTab,
    onClick,
    tabIndex,
    isActive,
    name,
}) => {
    const handleTabClick = (event) => {
        event.preventDefault();
        onClick(tabIndex);
    };

    const classNamesTabLink = cn(s.link, {
        [s.isActive]: isActive,
    });

    return (
        <li className={s.tab}>
            <a
                className={classNamesTabLink}
                href="/"
                onClick={handleTabClick}
                data-tab={tabIndex + 1}
                data-atst={name}
            >
                {titleTab}
            </a>
        </li>
    );
};

export default Tab;
