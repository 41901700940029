import React, { Component, ReactNode } from 'react';
import cn from 'classnames';

import s from './Tooltip.pcss';

interface ITooltipProps {
    position: 'top' | 'left' | 'right' | 'bottom';
    rootClassName?: string;
    wrapClassName?: string;
    contentClassName?: string;
    tooltipClassName?: string;
    bgColor?: 'red' | 'green';
    content: string | ReactNode;
    children: ReactNode;
}

export default class Tooltip extends Component<ITooltipProps> {
    render() {
        const {
            rootClassName,
            wrapClassName,
            contentClassName,
            children,
            content,
            tooltipClassName,
            bgColor,
            position,
        } = this.props;

        const colorClassName = bgColor ? s[bgColor] : '';

        return (
            <div className={cn(s.root, rootClassName)}>
                <div className={cn(s.content, wrapClassName)}>
                    {children}
                </div>
                <div className={cn(s.tooltip, colorClassName, s[position], tooltipClassName)}>
                    <div className={cn(s.tooltipBg, colorClassName)} />
                    <div className={cn(s.tooltipContent, contentClassName)}>{content}</div>
                </div>
            </div>
        );
    }
}
