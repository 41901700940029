import React from 'react';

import s from './Notice.pcss';

export const Notice: React.FC = () => (
    <p className={s.root}>
        Статистика по вашему сайту собирается вне зависимости
        от его участия в рейтинге и всегда доступна вам из
        {' '}
        <a
            href="https://id.rambler.ru/login-20/?registration_link=https%3A%2F%2Fid.rambler.ru%2Faccount%2Fexternal-registration%3Fback%3Dhttp://stat.top100.rambler.ru%2Fprojects%26rname%3Dtop100%26&back=http://stat.top100.rambler.ru%2Fprojects&rname=top100"
            className={s.link}
        >
            личного кабинета
        </a>
        .
    </p>
);
