import React from 'react';
import cn from 'classnames';
import s from './List.pcss';

interface IItem {
    readonly id: number;
    readonly text: string;
    readonly count?: number;
    readonly url?: string;
}

interface IListProps {
    mod?: 'default' | 'regions';
    data: IItem[];
    activeId?: string|number;
    onClickItem?: Function;
    className?: string;
    itemClassName?: string;
    countsMap?: { [id: number]: number };
    isShowCount?: boolean;
    itemTypes?: string;
}

interface IItemProps {
    itemClassName: string;
    item: IItem;
    isActive: boolean;
    onClickItem: Function;
    count?: number;
    isShowCount?: boolean;
    itemTypes?: string;
}

/**
 * Компонент элемента списка
 */
export const Item: React.FC<IItemProps> = ({
    itemClassName,
    item,
    isActive,
    onClickItem,
    count,
    isShowCount,
    itemTypes,
}) => (
    <div
        className={cn(s.item, { [s.active]: isActive }, itemClassName)}
        key={item.id}
    >
        <a
            className={s.itemUrl}
            href={item.url}
            onClick={() => onClickItem(item.id)}
            data-atst={`listItem-${itemTypes}`}
        >
            <span className={s.itemTitle}>{item.text}</span>
            {(isShowCount && Number.isInteger(count)) && (
                <span
                    className={s.itemCount}
                    children={count}
                />
            )}
        </a>
    </div>
);

/**
 * Компонент списка
 */
const List: React.FC<IListProps> = ({
    className = '',
    itemClassName = '',
    mod = 'default',
    data = [],
    activeId = 0,
    onClickItem = () => {},
    countsMap = {},
    isShowCount = true,
    itemTypes = 'default',
}) => {
    const cnList = cn(s.list, className, {
        [s.modDefault]: mod === 'default',
        [s.modRegions]: mod === 'regions',
    });

    return (
        <div className={cnList}>
            {data.map(item => Item({
                item,
                isShowCount,
                count: countsMap[item.id] === null ? null : (countsMap[item.id] || 0),
                isActive: activeId === item.id,
                onClickItem,
                itemClassName,
                itemTypes,
            }))}
        </div>
    );
};

export default List;
