import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './Paper.pcss';

interface IPaperProps {
    children: ReactNode;
    className: string;
}

const Paper: React.FC<IPaperProps> = ({ children, className }) => {
    const cnRoot = cn(s.root, className);

    return (
        <div className={cnRoot}>
            {children}
        </div>
    );
};

Paper.defaultProps = {
    className: '',
};

export default Paper;
