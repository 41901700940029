import React from 'react';
import Svg from '@components/Svg';

import s from './DisplayMobileFilters.pcss';

interface IProps {
    onHistory: Function;
    setRegionIds: Function;
    typesData: ITypesData[];
    filters: IActiveFilters;
    regionData: { [id: number]: IRegionType };
    categoryData: { [id: number]: ICategoryType };
}

const getTypeTitle = (data: ICategoryData[], id) => (
    data.find(item => item.id === id).text);
const getSubCategoryTitle = (data: ICategoryType[], id) => (
    data.find(item => item.theme_id === id).title);

export const DisplayMobileFilters: React.FC<IProps> = ({
    filters,
    typesData,
    onHistory,
    regionData,
    categoryData,
    setRegionIds,
}) => {
    const {
        typeId,
        regionId,
        categoryId,
        subcategoryId,
    } = filters;

    const regionTitle = regionId
        ? regionData[regionId].title
        : null;

    const typeTitle = typeId ? getTypeTitle(typesData, typeId) : null;

    const subcategoryTitle = subcategoryId
        ? getSubCategoryTitle(categoryData[categoryId].children, subcategoryId)
        : null;

    const handleClick = filter => (e) => {
        e.preventDefault();

        onHistory(filter);
        setRegionIds(0, {});
    };

    const showSelectedFilters = regionTitle || typeTitle || subcategoryTitle;

    return (
        showSelectedFilters ? (
            <div className={s.root}>
                {regionTitle && (
                    <span className={s.filter}>
                        {regionTitle}
                        <button
                            className={s.button}
                            onClick={handleClick({ regionId: '0' })}
                            type="button"
                        >
                            <Svg name="icon-close" />
                        </button>
                    </span>
                )}
                {typeTitle && (
                    <span className={s.filter}>
                        {typeTitle}
                        <button
                            className={s.button}
                            onClick={handleClick({ typeId: 0 })}
                            type="button"
                        >
                            <Svg name="icon-close" />
                        </button>
                    </span>
                )}
                {subcategoryTitle && (
                    <span className={s.filter}>
                        {subcategoryTitle}
                        <button
                            className={s.button}
                            onClick={handleClick({ subcategoryId: 0 })}
                            type="button"
                        >
                            <Svg name="icon-close" />
                        </button>
                    </span>
                )}
            </div>
        ) : null
    );
};
