import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@components/Paper';

import imageFile from '@images/mirtesen.png';

import s from './WorldNarrow.pcss';

interface IWorldNarrowProps {
    url: string;
}

const WorldNarrow: React.FC<IWorldNarrowProps> = ({ url }) => (
    <Paper className={s.root}>
        <Link className={s.link} to={url}>
            <img className={s.image} src={imageFile} alt="Мир Тесен" />
        </Link>
    </Paper>
);

WorldNarrow.defaultProps = {
    url: '',
};

export default WorldNarrow;
