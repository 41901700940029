import React from 'react';

import { defaultSchemaOrg } from '@constants/defaultSchemaOrg';
import { openGraphDefault } from '@configs/openGraph';

interface IProps {
    title: string;
    description?: string;
    datePublished?: string;
    author?: string;
}

export const SchemaOrg: React.FC<IProps> = ({
    title,
    description,
    datePublished,
    author,
}) => {
    const { defaultAuthor, defaultDatePublished } = defaultSchemaOrg;
    const defaultDescription = openGraphDefault.description;

    if (!title) return null;

    return (
        <span itemScope itemType="https://schema.org/WebPage">
            <meta itemProp="title" content={title} />
            <meta itemProp="description" content={description || defaultDescription} />
            <time itemProp="datePublished" dateTime={datePublished || defaultDatePublished} />
            <meta itemProp="author" content={author || defaultAuthor} />
        </span>
    );
};
