/* eslint-disable react/no-danger */

import React from 'react';

export const NoIndex: React.FC = ({ children }) => (
    <>
        <span dangerouslySetInnerHTML={{ __html: '<!--noindex-->' }} />
        {children}
        <span dangerouslySetInnerHTML={{ __html: '<!--/noindex-->' }} />
    </>
);
