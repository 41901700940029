import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Button from '@components/Button';
import { RegionsMobile } from '@components/Regions';
import { DropdownMobile } from '@components/Dropdown';

import { Select as NativeSelect } from '@components/Select';

import { rangeData } from './configs';

import { Item } from './Item';

import s from './Filters.pcss';

interface IMobileFiltersProps {
    regionIds: number[];
    onHistory: Function;
    setRegionIds: Function;
    typesData: ITypesData[];
    topLevelRegion: number[];
    activeFilters: IActiveFilters;
    subcategoryData: ICategoryData[];
    dictRegion: { [id: number]: IRegionType };
}

export const rangeSelect = rangeData.map(item => ({
    id: item.id,
    text: item.customLabel,
}));

export const MobileFilters: React.FC<IMobileFiltersProps> = ({
    onHistory,
    typesData,
    regionIds,
    dictRegion,
    setRegionIds,
    activeFilters,
    topLevelRegion,
    subcategoryData,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [subcategoryId, setSubcategoryId] = useState(0);
    const [typeId, setTypeId] = useState(0);
    const [regionId, setRegionId] = useState(0);
    const [showFilterSubcategory, setShowFilterSubcategory] = useState(!!subcategoryData.length);

    const handleApply = () => {
        onHistory({
            subcategoryId,
            typeId,
            regionId,
            page: 0,
        });
        setRegionIds(regionId, dictRegion);
        setIsOpened(false);
    };

    const handleCancel = () => setIsOpened(false);

    useEffect(() => {
        setShowFilterSubcategory(!!activeFilters.categoryId);
    }, [activeFilters.categoryId]);

    useEffect(() => {
        setTypeId(activeFilters.typeId);
        setSubcategoryId(activeFilters.subcategoryId);
        setRegionId(activeFilters.regionId);
    }, [activeFilters.typeId, activeFilters.subcategoryId, activeFilters.regionId]);

    return (
        <div className={s.filters}>
            <div className={s.filtersGroup}>
                <DropdownMobile
                    isOpened={isOpened}
                    onOpen={() => setIsOpened(true)}
                    onClose={() => setIsOpened(false)}
                    title="Настроить фильтр"
                    anchor={({ onOpen }) => (
                        <Item title="Настроить фильтр" onClick={onOpen} noArrow />
                    )}
                >
                    {showFilterSubcategory && (
                        <NativeSelect
                            data={subcategoryData}
                            activeId={subcategoryId}
                            onChange={id => setSubcategoryId(Number(id))}
                        />
                    )}
                    <RegionsMobile
                        dict={dictRegion}
                        selected={regionIds}
                        firstItems={topLevelRegion}
                        onChange={id => setRegionId(Number(id))}
                    />
                    <NativeSelect
                        data={typesData}
                        activeId={typeId}
                        onChange={id => setTypeId(Number(id))}
                    />
                    <div className={s.actions}>
                        <Button className={s.actionsItem} onClick={handleApply} primary>
                            Применить
                        </Button>
                        <Button className={s.actionsItem} onClick={handleCancel} noBordered>
                            Отменить
                        </Button>
                    </div>
                </DropdownMobile>
            </div>
            <div className={s.filtersGroup}>
                <NativeSelect
                    rootClassName={cn(s.filtersSelectRoot, s.filtersItem)}
                    selectClassName={cn(
                        s.filtersSelectNative,
                        s.filtersTitle,
                        s.filtersText,
                        s.bigWidth,
                    )}
                    data={rangeSelect}
                    activeId={activeFilters.period}
                    onChange={id => onHistory({ period: id })}
                />
            </div>
        </div>
    );
};
