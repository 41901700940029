import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { DropdownDesktop } from '@components/Dropdown';
import List from '@components/List';
import { Select } from '@components/Select';
import { defaultTexts } from '@constants/defaultTexts';

import { getTitle } from '@tools/getTitle';

import s from './Categories.pcss';

interface ICategoriesProps {
    onHistory: Function;
    filters: IActiveFilters;
    onSubcategoryData: Function;
    categoryData: ICategoryData[];
}

interface ICategoryLabelProps {
    activeId: number;
    onClick: Function;
    categoryData: ICategoryData[];
}

export const CategoryLabel: React.FC<ICategoryLabelProps> = ({
    onClick,
    activeId,
    categoryData,
}) => {
    const handleClick = () => onClick();

    return (
        <div
            className={cn(s.filtersItem, s.category)}
            onClick={handleClick}
            role="presentation"
            data-atst="categoryLabel"
        >
            <div className={s.filtersTitle}>
                <h1 className={s.filtersText}>
                    {getTitle(categoryData, activeId, defaultTexts.topics)}
                </h1>
            </div>
        </div>
    );
};

export const Categories: React.FC<ICategoriesProps> = ({
    filters,
    onHistory,
    categoryData,
    onSubcategoryData,
}) => {
    const isMobile = useSelector(({ runtime }) => runtime.isMobile);
    const [isOpenedCategory, setOpenedCategory] = useState(false);

    const handleChangeFilter = (id: number) => {
        onHistory({ categoryId: id }, {
            clearFilters: true,
        });
        onSubcategoryData(id);
        setOpenedCategory(false);
    };

    if (isMobile) {
        return (
            <Select
                data={categoryData}
                rootClassName={s.selectRoot}
                selectClassName={s.selectNative}
                activeId={filters.categoryId}
                onChange={handleChangeFilter}
            />
        );
    }

    return (
        <DropdownDesktop
            isOpened={isOpenedCategory}
            className={s.categories}
            onOpen={() => setOpenedCategory(true)}
            onClose={() => setOpenedCategory(false)}
            anchor={props => (
                <CategoryLabel
                    onClick={props.onToggle}
                    categoryData={categoryData}
                    activeId={filters.categoryId}
                />
            )}
        >
            <List
                isShowCount={false}
                data={categoryData}
                activeId={filters.categoryId}
                onClickItem={handleChangeFilter}
                itemTypes="category"
            />
        </DropdownDesktop>
    );
};
