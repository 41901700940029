import React, { Component, ReactElement, MouseEvent } from 'react';
import Svg from '@components/Svg';
import { Loader } from '@components/Loader';
import { eventsYm, eventsTop100 } from '@tools/events';
import { defaultTexts } from '@constants/defaultTexts';
import s from './Tabs.pcss';

interface ITabsProps {
    children: React.ReactNode;
    region?: string;
    request: boolean;
    onClick: (event: MouseEvent) => void;
    onChange?: () => void;
}

interface ITabsState {
    activeTabIndex: number;
}

const Region = ({ region = defaultTexts.regions, onClick }) => (
    <button
        onClick={onClick}
        className={s.region}
        type="button"
        data-atst="regionBtn"
    >
        <Svg className={s.regionIcon} name="icon-region" />
        {region}
    </button>
);

// TODO: - я не понимаю что это, называется как что-то универсальное но им не является
export default class Tabs extends Component<ITabsProps, ITabsState> {
    static defaultProps = {
        activeTabIndex: 0,
    };

    constructor(props: ITabsProps) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        };
    }

    handleTabClick = (tabIndex) => {
        const { onChange } = this.props;
        // TODO: - из коммента выше, если этот компонент будет переделан во что-то общее
        // TODO: - то и кусок кода ниже тоже нужно вынести в отдельное место
        eventsYm.clickBlockPopular();
        if (tabIndex === 0) {
            eventsYm.showBlockPopular();
            eventsTop100.sendCVShowTabPopular();
        } else if (tabIndex === 1) {
            eventsYm.showBlockNew();
            eventsTop100.sendCVShowTabNew();
        }

        this.setState({
            activeTabIndex: tabIndex,
        }, () => {
            if (onChange) onChange();
        });
    };

    renderTabs() {
        const { children } = this.props;
        const { activeTabIndex } = this.state;

        return React.Children.map(children, (child: ReactElement, index) => (
            React.cloneElement(child, {
                onClick: this.handleTabClick,
                tabIndex: index,
                isActive: index === activeTabIndex,
            })
        ));
    }

    renderActiveTabContent() {
        const { children } = this.props;
        const { activeTabIndex } = this.state;

        if (children[activeTabIndex]) {
            const showMore = children[activeTabIndex].props.showMore
                && children[activeTabIndex].props.showMore;

            return (
                <div className={s.wrap}>
                    <div className={s.content}>
                        { children[activeTabIndex].props.children }
                    </div>
                    { showMore }
                </div>
            );
        }

        return null;
    }

    render() {
        const { region, onClick, request } = this.props;

        return (
            <div className={s.root}>
                <div className={s.nav}>
                    <ul className={s.list}>
                        {this.renderTabs()}
                    </ul>
                    <Region
                        onClick={onClick}
                        region={region}
                    />
                </div>
                {request ? <Loader /> : this.renderActiveTabContent()}
            </div>
        );
    }
}
