import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import RUIAd from '@rambler-components/ad';
import { RouteComponentProps, withRouter } from 'react-router';

import s from './Ad.pcss';

interface IProps extends RamblerComponents.Ad.IProps {
    showSkeleton?: boolean;
    reverseScrollAtHeightBlock?: boolean;
    canUpdate?: boolean;
    positionButtonPrime?: 'top' | 'bottom';
    ignoreBackgroundPrime?: boolean;
    ignoreButtonPrime?: boolean;
    bannerNumber: number;
}

const Ad: React.FC<IProps & RouteComponentProps> = ({
    showSkeleton = false,
    canUpdate,
    id,
    ...props
}): JSX.Element => {
    const isMobile = useSelector((state: Record<any, any>) => state.runtime.isMobile);
    const reloadKey = useSelector(({ global }): number => global.adKey);
    const [isVisibleBanner, setVisibleBanner] = useState(false);

    const ref: React.Ref<HTMLDivElement> = useRef(null);

    useEffect(() => {
        const callback = () => {
            const { offsetHeight } = ref.current;
            if (offsetHeight > 0) setVisibleBanner(true);
        };

        const observer = new MutationObserver(callback);

        observer.observe(ref.current, {
            childList: true,
            subtree: true,
        });

        return () => observer.disconnect();
    }, [isVisibleBanner]);

    const adProps = {
        ...props,
        id,
        reloadKey: canUpdate ? reloadKey : 0,
        key: id,
    };

    const classAdv = 'ads-block';

    return (
        <div
            className={cn({
                [s.skeletonAd]: showSkeleton,
                [s.mobile]: isMobile,
                [classAdv]: showSkeleton,
            })}
        >
            <div
                ref={ref}
                data-banner={id}
            >
                <RUIAd {...adProps} />
            </div>
        </div>
    );
};

export default withRouter(Ad);
