import { connect } from 'react-redux';

import { actions } from '@redux/modules/filters';
import { sitesTableAds } from '@configs/ad';
import { getAdConfig } from '@tools/ad';

import TableSites from './TableSites';

const mapStateToProps = state => ({
    isMobile: state.runtime.isMobile,
    ads: getAdConfig(sitesTableAds, state.runtime.isMobile),
    projectCount: state.sites.projectCount,
    data: state.sites.data,
    sort: state.filters.activeFilters.sort,
    currentPage: state.sites.currentPage,
    request: state.sites.request,
    resourceId: state.position.resourceId,
});

const mapDispatchToProps = dispatch => ({
    onChangeSort: ({ key, order }: { key: string; order: string }) => (
        dispatch(actions.SET_SORT({ key, order }))
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TableSites);
