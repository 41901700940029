import { ISnackbar } from '@redux/modules/global';

const config: Record<string, ISnackbar> = {
    TWO_TIMEOUT_EXPIRES: {
        message: 'Новости чуть-чуть задерживаются. Оставайтесь с нами',
        positionY: 'bottom',
        positionX: 'left',
        size: 'medium',
        type: 'primary',
    },
    ADBLOCK: {
        message: 'Из-за блокировщика рекламы в&nbsp;вашем браузере некоторые элементы сайта могут работать неправильно. Отключите блокировщик или добавьте Рамблер/Топ-100 в&nbsp;исключения!',
        positionY: 'bottom',
        positionX: 'right',
        size: 'medium',
        type: 'main',
        className: 'adblock',
        showClose: true,
        delay: 0,
    },
};

export default config;
