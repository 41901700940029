import { openGraph, openGraphDefault, openGraphReplacement } from '@configs/openGraph';
import { eventsTop100 } from '@tools/events';

// Ссылки строим с раздела каталога /navi
// Это костыль для Демо сайта
export const setPageViewMedia = (
    seoFragment: string = '',
    urlData: any,
): void => {
    let { title, description } = seoFragment ? openGraph : openGraphDefault;
    const createUrl = (params) => {
        let url = 'https://top100.rambler.ru/navi';
        const query = params && Object.keys(params).reduce((
            acc, cur,
        ) => (params[cur] ? `${acc}&${cur}=${params[cur]}` : acc), '');

        if (query) url = `${url}?${query.slice(1)}`;
        return url;
    };

    if (seoFragment) {
        title = title.replace(openGraphReplacement, seoFragment);
        description = description.replace(openGraphReplacement, seoFragment);
    }

    eventsTop100.setPageViewMedia(title, description, createUrl(urlData));
};
