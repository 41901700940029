import * as React from 'react';
import cn from 'classnames';

import { SORT_VIEWERS, SORT_VIEWS, SORT_POPULARITY } from '@constants/sort';
import { DEFAULT_PAGE_LIMIT } from '@constants/global';

import Ad from '@components/Ad';
import Tooltip from '@components/Tooltip';
import Svg from '@components/Svg';
import { Favicon } from '@components/Favicon';
import { Loader } from '@components/Loader';
import { plural } from '@tools/string';
import { IBanner } from '@configs/ad';
import Table from '@components/Table';
import { Formatter } from 'tools/formatter';
import { NoIndex } from '@components/NoIndex';
import { eventsTop100 } from '@tools/events';

import { Select, ISelectItem } from '@components/Select';
import s from './TableSites.pcss';

interface ITableSitesProps {
    isMobile: boolean;
    ads: Record<string, IBanner>;
    data: SitesType[];
    sort: string;
    onChangeSort: Function;
    projectCount: number;
    currentPage: number;
    request: boolean;
    resourceId: number;
    onHistory: Function;
}

interface IColumnData { key: string; name: string; tooltip: string }

const columnsData: IColumnData[] = [
    {
        key: SORT_VIEWERS,
        name: 'Посетители',
        tooltip: 'Посетители — количество уникальных посетителей сайта за выбранный период.',
    },
    {
        key: SORT_VIEWS,
        name: 'Просмотры',
        tooltip: 'Просмотры — количество хитов (просмотров страниц) на сайте за выбранный период.',
    },
    {
        key: SORT_POPULARITY,
        name: 'Популярность',
        tooltip: 'Формула расчёта популярности учитывает визиты за вычетом отказов, посетителей сайта и глубину просмотра. Наибольший вес имеют визиты за вычетом отказов. В таблице отображается среднее за период.',
    },
];

const columnsSelect: ISelectItem[] = columnsData.map(column => ({
    id: column.key,
    text: column.name,
}));

export default class TableSites extends React.Component<ITableSitesProps> {
    anchor: React.RefObject<HTMLAnchorElement>; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        this.anchor = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            const { resourceId } = this.props;
            const { current } = this.anchor;
            if (current && window.location.href.includes(`${resourceId}`)) {
                current.scrollIntoView({
                    block: 'start',
                    inline: 'center',
                });
            }
        }, 1000);
    }

    renderColumn = (item: IColumnData, key: number) => {
        const { onHistory, sort } = this.props;
        const changeSort = () => {
            if (sort === item.key) {
                return;
            }

            onHistory({ sort: item.key, page: 0 });
        };

        const isActive = sort === item.key;

        return (
            <React.Fragment key={key}>
                <Table.Cell className={s.cell} tag="th" onClick={changeSort} hideMobile>
                    <Tooltip
                        position="bottom"
                        wrapClassName={cn(s.tooltipWrap, { [s.tooltipActive]: isActive })}
                        contentClassName={s.tooltipContent}
                        content={item.tooltip}
                    >
                        {item.name}
                        {isActive && (
                            <Svg
                                className={s.sortIcon}
                                name="icon-filter-arrow"
                            />
                        )}
                    </Tooltip>
                </Table.Cell>
            </React.Fragment>
        );
    };

    renderItem = (item: SitesType, index: number) => {
        const { resourceId } = this.props;
        const { sort } = this.props;
        const urlStat = `https://stat.top100.rambler.ru/projects/${item.id}/new/report/rating/`;
        const isUpPosition = item.rating.popularity.current > item.rating.popularity.previous;
        const ratingPopularity = (
            <>
                {Formatter.numberWithSpaces(item.rating.popularity.current)}
                <Svg
                    className={cn(s.popularityIcon, { [s.down]: !isUpPosition })}
                    name="icon-popularity-delta"
                />
            </>
        );
        const infoTextLinkContent = (
            <>
                <Favicon favicon={item.favicon} />
                &nbsp;
                {item.name}
            </>
        );

        return (
            <Table.Row
                isDifferentColors
                selected={resourceId === item.id}
                className={cn({ [s.oddRow]: !(index % 2) })}
            >
                <Table.Cell className={s.firstCell}>
                    <div className={s.info}>
                        {/* TODO: Зачем здесь ссылка? */}
                        <a
                            href={`#${index + 1}`}
                            id={String(index + 1)}
                            className={s.infoTextLine}
                        >
                            {`${index + 1}.`}
                        </a>
                        <div className={s.infoTextLines}>
                            <div className={s.infoTextLinesHead}>
                                <a
                                    href={item.url}
                                    className={cn(s.infoTitle, s.link)}
                                    id={String(item.id)}
                                    data-catalogue={item.id}
                                    ref={resourceId === item.id ? this.anchor : null}
                                    target="_blank"
                                    rel="noopener nofollow"
                                >
                                    {infoTextLinkContent}
                                </a>
                                {/* Дубль содержимого ссылки на случай,
                                    если adBlock скроет ссылку */}
                                <span className={cn(s.infoTitle, s.link)}>
                                    {infoTextLinkContent}
                                </span>
                            </div>
                            <NoIndex>
                                <a
                                    href={urlStat}
                                    className={cn(s.infoUrl, s.link)}
                                    onClick={eventsTop100.sendCVRatingLinkStatistic}
                                >
                                    Статистика по&nbsp;
                                    {item.url}
                                </a>
                            </NoIndex>
                        </div>
                    </div>
                </Table.Cell>
                <Table.Cell
                    textAlign="right"
                    hideMobile
                >
                    {Formatter.numberWithSpaces(item.rating.viewers)}
                </Table.Cell>
                <Table.Cell
                    textAlign="right"
                    hideMobile
                >
                    {Formatter.numberWithSpaces(item.rating.views)}
                </Table.Cell>
                <Table.Cell
                    textAlign="right"
                    hideMobile
                >
                    {ratingPopularity}
                </Table.Cell>
                <Table.Cell
                    className={s.cellRatingMobile}
                    onlyMobile
                >
                    {sort === SORT_VIEWERS ? item.rating.viewers : null}
                    {sort === SORT_VIEWS ? item.rating.views : null}
                    {sort === SORT_POPULARITY ? ratingPopularity : null}
                </Table.Cell>
                <Table.Cell
                    className={s.cellRatingMobile}
                    onlyMobile
                    textAlign="right"
                >
                    <a className={s.linkStat} href={urlStat}>
                        <Svg className={s.arrowIcon} name="icon-stat" />
                    </a>
                </Table.Cell>
            </Table.Row>
        );
    };

    renderAd = (banner: IBanner) => {
        const { isMobile } = this.props;

        const colspan = isMobile ? 3 : 4;
        return (
            <Table.Row>
                <Table.Cell colSpan={colspan} className={cn(s.cellSites, s.cellAd)}>
                    <Ad canUpdate isLazy {...banner} />
                </Table.Cell>
            </Table.Row>
        );
    };

    renderContent = () => {
        const { data, ads, currentPage } = this.props;
        if (!data.length) return null;

        return data.map((item, index) => {
            let ad = null;

            if (ads[index]) {
                ad = this.renderAd(ads[index]);
            }

            return (
                <React.Fragment key={item.url}>
                    {this.renderItem(
                        item,
                        index + ((currentPage - 1) * DEFAULT_PAGE_LIMIT),
                    )}
                    {ad}
                </React.Fragment>
            );
        });
    };

    render() {
        const {
            projectCount,
            sort,
            onChangeSort,
            request,
            onHistory,
        } = this.props;
        return (
            <div className={s.wrapper}>
                { request ? <Loader /> : (
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.Cell className={s.cellSites} tag="th">
                                    {Formatter.numberWithSpaces(projectCount)}
                                    &nbsp;
                                    {plural(projectCount, 'сайт', 'сайта', 'сайтов')}
                                </Table.Cell>
                                {columnsData.map(this.renderColumn)}
                                <Table.Cell
                                    className={s.cellSelect}
                                    tag="th"
                                    colSpan={2}
                                    onlyMobile
                                >
                                    <Select
                                        data={columnsSelect}
                                        activeId={sort}
                                        onChange={(key) => {
                                            onHistory({ sort: key });
                                            onChangeSort({ key, order: 'desc' });
                                        }}
                                        rootClassName={s.selectRoot}
                                        selectClassName={s.selectRoot}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body children={this.renderContent()} />
                    </Table.Root>
                )}
            </div>
        );
    }
}
