import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { QueryString } from '@tools/queryString';
import Button from '@components/Button';
import Svg from '@components/Svg';
import { eventsYm } from '@tools/events';

import s from './Search.pcss';

interface IProps {
    searchValue: string;
    className?: string;
    history?: History;
}

export const SearchForm: React.FC<IProps & RouteComponentProps> = ({
    history,
    searchValue,
    className,
}) => {
    const [value, setSearchValue] = useState(searchValue);
    const [canClearSearch, setCanClearSearch] = useState(value.trim().length > 0);

    useEffect(() => {
        setSearchValue(searchValue);
        setCanClearSearch(searchValue.length > 0);
    }, [searchValue]);

    const action = async () => {
        // Из значения вначале вырезается все включая и до '://'
        const newSearchValue = value.replace(/^.*:\/\//i, '');
        const queryParams = QueryString.getString({ query: newSearchValue });

        history.push({
            pathname: '/search',
            search: queryParams,
        });

        setSearchValue(newSearchValue);
        setCanClearSearch(false);
    };

    const handleSearchField = ({ target }) => {
        setSearchValue(target.value);
        setCanClearSearch(target.value.length > 0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        eventsYm.clickSearchButton();

        action();
    };

    const handleCleanerField = () => {
        setSearchValue('');
        setCanClearSearch(false);
        history.push({
            search: '',
        });
    };

    const handleDecodeUrl = () => {
        try {
            return decodeURIComponent(value);
        } catch (error) {
            return value;
        }
    };

    return (
        <div className={cn(s.root, className)}>
            <form
                className={s.form}
                onSubmit={handleSubmit}
            >
                <input
                    className={s.field}
                    type="search"
                    name="searchValue"
                    value={handleDecodeUrl()}
                    onChange={handleSearchField}
                    placeholder="Сайты в каталоге"
                />
                {canClearSearch && (
                    <Button
                        onClick={handleCleanerField}
                        className={s.cleaner}
                        type="reset"
                    >
                        <Svg className={s.cleanerIcon} name="icon-input-cleaner" />
                    </Button>
                )}
                <Button
                    className={s.button}
                    secondary
                    children="Найти"
                    type="submit"
                    icon={<Svg name="icon-search_form-search" />}
                    iconClassName={s.iconSearch}
                />
            </form>
        </div>
    );
};
