import React, { useRef, useEffect, ReactNode } from 'react';
import cn from 'classnames';

import s from './Dropdown.pcss';

interface IDropdownProps {
    className?: string;
    contentClassName?: string;
    noPaddingContent?: boolean;
    noBorderContent?: boolean;
    lightShadowContent?: boolean;
    anchor: Function;
    children: ReactNode;
    onOpen: Function;
    onClose: Function;
    isOpened?: boolean;
}

export const DropdownDesktop: React.FC<IDropdownProps> = ({
    isOpened = false,
    className = '',
    contentClassName = '',
    noPaddingContent = false,
    noBorderContent = false,
    lightShadowContent = false,
    anchor = () => {},
    onOpen = () => {},
    onClose = () => {},
    children,
}) => {
    const wrapperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            isOpened
            && wrapperRef
            && !wrapperRef.current.contains(event.target)
        ) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const handleToggle = () => {
        const toggleOpened = !isOpened;
        const propsCallback = toggleOpened ? onOpen : onClose;

        propsCallback();
    };

    const contentClassNames = cn(s.content, contentClassName, {
        [s.noPadding]: noPaddingContent,
        [s.lightShadow]: lightShadowContent,
        [s.noBorder]: noBorderContent,
    });

    return (
        <div className={className} ref={wrapperRef}>
            <div className={s.anchor}>{anchor({ onToggle: handleToggle, isOpened })}</div>
            <div className={s.dropdown}>
                {isOpened && (
                    <div className={contentClassNames}>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};
