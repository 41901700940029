import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './Table.pcss';

interface IHeaderProps {
    className?: string;
    children: ReactNode;
}

const Header: React.FC<IHeaderProps> = ({ className, children }) => (
    <thead className={cn(s.header, className)}>
        {children}
    </thead>
);

export default Header;
