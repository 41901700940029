/**
 * Получаем очишенное название раздела сайта
 * @param pathname
 */
export const getCleanPathname = (pathname: string) => {
    if (pathname === '/') return 'head';

    const split = pathname.split('/');
    if (split.length > 1) return split[1];

    return 'none';
};
