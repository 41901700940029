import React from 'react';
import List from '@components/List';
import Button from '@components/Button';
import Svg from '@components/Svg';
import { defaultTexts } from '@constants/defaultTexts';
import { stubs } from '@constants/stubs';

import s from './Regions.pcss';

interface IRegionsProps {
    dict: { [id: number]: IRegionType };
    firstItems: number[];
    selected: number[];
    onApply: Function;
    onCancel: Function;
    countsMap: { [id: number]: number };
    isShowCount?: boolean;
}

interface IRegionsState {
    selected: number[];
}

export class RegionsDesktop extends React.Component<IRegionsProps, IRegionsState> {
    constructor(props: Readonly<IRegionsProps>) {
        super(props);
        const { selected } = this.props;

        this.state = { selected };
    }

    prepareItems = (items: IRegionType[]) => (items.map(item => ({
        id: item.id,
        text: item.title,
    })));

    handleClickItem = (id: number, currentSelected: number[] = []) => {
        const selected = id ? [...currentSelected, id]: [];
        this.setState({ selected });
    };

    handleClear = () => {
        this.setState({ selected: [] });
    };

    handleClickCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    renderListItem = (id: number, index: number, currentSelected: number[] = []) => {
        const { selected } = this.state;
        const { dict, countsMap, isShowCount } = this.props;
        const items = dict[id] ? this.prepareItems(dict[id].children) : [];

        if (items.length === 0) {
            return null;
        }

        return (
            <React.Fragment key={index}>
                <List
                    isShowCount={isShowCount}
                    countsMap={countsMap}
                    itemClassName={s.listItem}
                    className={s.list}
                    mod="regions"
                    onClickItem={item => this.handleClickItem(item, currentSelected)}
                    data={items}
                    activeId={selected[index + 1]}
                    itemTypes="regions"
                />
            </React.Fragment>
        );
    };

    renderSelectedItem = (id: number, key: number) => {
        const { dict } = this.props;
        let item = dict[id];

        if (String(id) === '0') {
            item = stubs.regions;
        }

        return (
            <div
                key={key}
                className={s.selectedItem}
                data-atst="regions-selectedItem"
            >
                {item.title}
            </div>
        );
    };

    render() {
        const { selected } = this.state;
        const {
            countsMap,
            firstItems,
            dict,
            onApply,
            isShowCount,
        } = this.props;
        const items = [
            {
                id: 0,
                text: defaultTexts.regions,
            },
            ...this.prepareItems(firstItems.map(id => dict[id])),
        ];
        const copySelected = [...selected];

        return (
            <>
                <div className={s.lists}>
                    <List
                        isShowCount={isShowCount}
                        countsMap={countsMap}
                        itemClassName={s.listItem}
                        className={s.list}
                        mod="regions"
                        onClickItem={this.handleClickItem}
                        data={items}
                        activeId={selected.length ? selected[0] : 0}
                        itemTypes="regions"
                    />
                    {selected.length > 0 && selected.map((id, index) => this.renderListItem(
                        id,
                        index,
                        copySelected.filter((id, i) => i <= index), // eslint-disable-line
                    ))}
                </div>
                <div className={s.bottom}>
                    <div className={s.selected}>
                        <div className={s.selectedTitle}>Выбранный регион:</div>
                        <div className={s.selectedContent}>
                            {selected.map(this.renderSelectedItem)}
                            {!selected.length && (
                                <div className={s.selectedItem}>
                                    Все регионы
                                </div>
                            )}
                            {selected.length > 0 && (
                                <Svg
                                    name="icon-close"
                                    className={s.selectedClose}
                                    onClick={this.handleClear}
                                />
                            )}
                        </div>
                    </div>
                    <div className={s.actions}>
                        <Button
                            noBordered
                            onClick={this.handleClickCancel}
                            className={s.actionsButton}
                            children="Отменить"
                            dataAttributes={{
                                name: 'data-atst',
                                value: 'regions-cancelBtn',
                            }}
                        />
                        <Button
                            secondary
                            className={s.actionsButton}
                            onClick={() => onApply(selected)}
                            children="Применить"
                            dataAttributes={{
                                name: 'data-atst',
                                value: 'regions-applyBtn',
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}
