import React, { Component, ReactNode } from 'react';
import Svg from '@components/Svg';
import s from './Dropdown.pcss';

interface IDropdownProps {
    isOpened: boolean;
    onOpen: Function;
    onClose: Function;
    anchor: Function;
    title: string;
    children: ReactNode;
}

interface IDropdownState {
    isOpened: boolean;
}

export default class Dropdown extends Component<IDropdownProps, IDropdownState> {
    static getDerivedStateFromProps(
        nextProps: Readonly<IDropdownProps>,
    ) {
        return { isOpened: nextProps.isOpened };
    }

    state = {
        isOpened: false,
    };

    constructor(props: Readonly<IDropdownProps>) {
        super(props);

        const { isOpened } = this.props;

        this.state = { isOpened };
    }

    handleOpen = () => {
        const { onOpen } = this.props;
        onOpen();
        this.setState({ isOpened: true });
    };

    handleClose = () => {
        const { onClose } = this.props;
        onClose();
        this.setState({ isOpened: false });
    };

    render() {
        const { anchor, title, children } = this.props;
        const { isOpened } = this.state;

        const anchorProps = {
            onOpen: this.handleOpen,
        };

        return (
            <div className={s.root}>
                {anchor(anchorProps)}
                {isOpened && (
                    <div className={s.rootWrap}>
                        <div className={s.header}>
                            <div className={s.headerWrap}>
                                <div className={s.title}>{title}</div>
                                <Svg
                                    onClick={this.handleClose}
                                    name="icon-close"
                                    className={s.close}
                                />
                            </div>
                        </div>
                        <div className={s.content}>
                            {children}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
