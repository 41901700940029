import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './Table.pcss';

interface IWrapperProps {
    className?: string;
    children: ReactNode;
}

const Body: React.FC<IWrapperProps> = ({ className, children }) => (
    <tbody className={cn(s.body, className)}>
        {children}
    </tbody>
);

export default Body;
