import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { History } from 'history';
import Layout from '@components/Layout';
import { Categories } from '@components/Categories';
import { Pagination } from '@components/Pagination';
import { Filters } from '@components/Filters';
import { RecomCategories } from '@components/RecomCategories';
import { NoResult } from '@components/NoResult';
import { Notice } from '@components/Notice';
import TableSites from '@containers/TableSites';
import Sites, { IPropsChildren } from '@containers/Sites';
import { QueryString } from '@tools/queryString';
import { getDocumentTitle } from '@tools/documentTitle';
import { getTitleSeoFragment } from '@tools/getTitleSeoFragment';
import { setMetaTags } from '@tools/setMetaTags';

export interface IProps {
    history: History;
    onHistory: Function;
    onSubcategoryData: Function;
}

const Search: React.FC<IProps> = ({ history }) => {
    const searchString = QueryString.getObject(history.location.search).query;
    const formatSearchString = searchString && searchString.length
        ? searchString.replace(/[\\]/g, '\\\\')
        : searchString;

    const categoryIds = useSelector((state: Record<any, any>) => state.category);
    const filtersIds = useSelector((state: Record<any, any>) => state.filters);
    const regionData = useSelector(({ region }) => region.data);
    const typesData = useSelector(({ types }) => types.data);

    const titleSeoFragment = getTitleSeoFragment(categoryIds, filtersIds, regionData, typesData);
    const documentTitle = getDocumentTitle(titleSeoFragment);
    const {
        categoryId,
        subcategoryId,
        regionId,
        typeId,
    } = filtersIds.activeFilters;

    useEffect(() => {
        setMetaTags(titleSeoFragment);

        return () => setMetaTags();
    }, [categoryId, subcategoryId, regionId, typeId]);

    return (
        <DocumentTitle title={documentTitle}>
            <Layout>
                <Sites history={history}>
                    {({
                        isMobile,
                        regionIds,
                        onHistory,
                        dictRegion,
                        setFilters,
                        clearFilter,
                        currentPage,
                        filterTypes,
                        projectCount,
                        setRegionIds,
                        categoryData,
                        activeFilters,
                        filterRegions,
                        topLevelRegion,
                        subcategoryData,
                        showPlaceholder,
                        defaultPageLimit,
                        dictCategoryData,
                        filterCategories,
                        onSubcategoryData,
                    }: IPropsChildren) => (
                        !showPlaceholder ? (
                            <>
                                <Categories
                                    onHistory={onHistory}
                                    filters={activeFilters}
                                    categoryData={categoryData}
                                    onSubcategoryData={onSubcategoryData}
                                />
                                {!isMobile && <RecomCategories searchString={formatSearchString} />}
                                <Filters
                                    history={history}
                                    typesData={typesData}
                                    onHistory={onHistory}
                                    regionIds={regionIds}
                                    setFilters={setFilters}
                                    dictRegion={dictRegion}
                                    filterTypes={filterTypes}
                                    clearFilter={clearFilter}
                                    setRegionIds={setRegionIds}
                                    filterRegions={filterRegions}
                                    activeFilters={activeFilters}
                                    topLevelRegion={topLevelRegion}
                                    subcategoryData={subcategoryData}
                                    dictCategoryData={dictCategoryData}
                                    filterCategories={filterCategories}
                                />
                                <TableSites
                                    onHistory={onHistory}
                                />
                                <Pagination
                                    showElementBothSide={8}
                                    defaultLimit={defaultPageLimit}
                                    projectCount={projectCount}
                                    currentPage={currentPage}
                                    onNextPage={onHistory}
                                />
                            </>
                        ) : (
                            <>
                                <NoResult history={history} />
                                <Notice />
                            </>
                        )
                    )}
                </Sites>
            </Layout>
        </DocumentTitle>
    );
};

export default Search;
