import React from 'react';
import cn from 'classnames';
import Svg from '@components/Svg';
import { Formatter } from '@tools/formatter';
import { plural } from '@tools/string';

import s from './Totals.pcss';

interface IProps {
    className: string;
    siteCount: number;
    yesterdayViews: number;
    isMobile: boolean;
}

const Totals: React.FC<IProps> = ({
    siteCount = 0,
    yesterdayViews = 0,
    className,
    isMobile = false,
}) => (
    <div className={cn(s.totals, className)}>
        {siteCount ? (
            <span className={s.totalsItem}>
                <Svg className={s.icon} name="icon-earth" />
                {Formatter.numberWithSpaces(siteCount)}
                {' '}
                {plural(siteCount, 'сайт', 'сайта', 'сайтов')}
            </span>
        ) : null}
        {yesterdayViews ? (
            <span className={s.totalsItem}>
                <Svg className={s.icon} name="icon-eye" />
                {Formatter.numberWithSpaces(yesterdayViews)}
                {' '}
                {!isMobile && plural(yesterdayViews, 'просмотр', 'просмотра', 'просмотров')}
                {' '}
                за сутки
            </span>
        ) : null}
    </div>
);

export default Totals;
