import { IAds, IBanner } from '@configs/ad';

/**
 * Вспомогательаня функция для получения активного конфига рекламы
 */
export const getAdConfig = (config: IAds, isMobile: boolean): Record<string, IBanner> => {
    const { mobile, desktop, all } = config;

    return {
        ...(isMobile ? (mobile || {}) : (desktop || {})),
        ...(all || {}),
    };
};
