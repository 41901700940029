import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Svg from '@components/Svg';
import openstats from '@constants/openstats';

import s from './MainLogo.pcss';

interface IProps {
    className: string;
}

const MainLogo: React.FC<IProps> = ({ className }) => (
    <div className={cn(s.logo, className)}>
        <a
            className={s.logoRambler}
            href="http://rambler.ru/?utm_source=top100&utm_content=head&utm_medium=logo&utm_campaign=self_promo"
            data-atst="logoRambler"
        >
            <Svg name="rambler-logo" />
        </a>
        <Link
            className={s.logoTop}
            to={'/'
                + `${openstats.name}`
                + `${openstats.service.catalogue}`
                + `${openstats.campaign.sberAds}`
                + `${openstats.ad.logoTop100}`
                + `${openstats.source.catalogueHeader}`
            }
        >
            <Svg name="logo_top100" />
        </Link>
    </div>
);

export default MainLogo;
