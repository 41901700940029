import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import s from './RecomCategories.pcss';

interface IRecomCategoriesProps {
    searchString: string;
}

type ResultCategory = [number, number?][];

export const RecomCategories: React.FC<IRecomCategoriesProps> = ({ searchString }) => {
    const categoriesDict = useSelector(({ category }) => category.data);
    const defaultCategories: ResultCategory = [[1000, 1001], [1000, 1002], [1255, 1256]];
    let result = defaultCategories;

    /**
     * Поиск id категорий по тайтлу
     */
    const findCategories = (): ResultCategory => {
        const findResult: ResultCategory = [];

        const rootItems: ICategoryType[] = Object.values(categoriesDict);

        rootItems.forEach((rootCategory) => {
            const findInCategory = [];
            const test = testString => testString.toLowerCase().search(searchString) !== -1;

            const rootFind = test(rootCategory.title);

            if (rootCategory.children) {
                rootCategory.children.forEach((childItem) => {
                    if (test(childItem.title)) {
                        findInCategory.push([rootCategory.id, childItem.theme_id]);
                    }
                });
            }

            if (findInCategory.length !== 0) {
                findResult.push(...findInCategory);
            } else if (findInCategory.length === 0 && rootFind) {
                findResult.push([rootCategory.id]);
            }
        });

        return findResult.slice(0, 3);
    };

    /**
     * Выделяем подстроку по поисковой фразе
     */
    const highlightString = (str) => {
        if (!str || !str.length) return null;

        const findIndex = str.search(new RegExp(searchString, 'i'));

        if (!searchString || findIndex === -1) return str;

        return (
            <>
                {str.substr(0, findIndex)}
                <span className={s.bold}>{str.substr(findIndex, searchString.length)}</span>
                {str.substr(findIndex + searchString.length, str.length)}
            </>
        );
    };

    if (searchString && searchString.length !== 0) {
        result = findCategories();
    }

    if (!result.length) return null;

    return (
        <div className={s.root}>
            <span className={s.title}>Рекомендуемые разделы:</span>
            {result.map((ids): JSX.Element => {
                const parent = categoriesDict[ids[0]];
                const child = ids[1]
                    ? parent.children.find(item => item.theme_id === ids[1])
                    : null;

                const href = `/navi?categoryId=${parent.id}${child ? `&subcategoryId=${child.id}` : ''}`;

                const parentTitle = highlightString(parent.title);
                const childTitle = child && highlightString(child.title);

                return (
                    <Link to={href} className={s.result} key={ids.toString()}>
                        {parentTitle}
                        {child ? ' / ' : null}
                        {childTitle}
                    </Link>
                );
            })}
        </div>
    );
};
