import { openGraph, openGraphDefault, openGraphReplacement } from '@configs/openGraph';

export const setMetaTags = (
    seoFragment: string = '',
): void => {
    const metaTitle = document.querySelector('meta[name="title"]');
    const metaDescription = document.querySelector('meta[name="description"]');
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogDescription = document.querySelector('meta[property="og:description"]');
    const twitterTitle = document.querySelector('meta[property="twitter:title"]');
    const twitterDescription = document.querySelector('meta[property="twitter:description"]');

    let { title, description } = seoFragment ? openGraph : openGraphDefault;

    if (seoFragment) {
        title = title.replace(openGraphReplacement, seoFragment);
        description = description.replace(openGraphReplacement, seoFragment);
    }

    metaTitle.setAttribute('content', title);
    metaDescription.setAttribute('content', description);

    if (ogTitle && ogDescription) {
        ogTitle.setAttribute('content', title);
        ogDescription.setAttribute('content', description);
    }

    if (twitterTitle && twitterDescription) {
        twitterTitle.setAttribute('content', title);
        twitterDescription.setAttribute('content', description);
    }
};
