import React from 'react';
import cn from 'classnames';

import Svg from '@components/Svg';

import s from './List.pcss';

interface IItem {
    id: string;
    text: string;
}

interface IItemProps {
    item: IItem;
    activeId: string;
    onClickItem: Function;
    itemTypes: string;
}

interface IListProps {
    data: IItem[];
    activeId: string;
    onClickItem: Function;
    itemTypes?: string;
}

const Item: React.FC<IItemProps> = ({
    item,
    activeId,
    onClickItem,
    itemTypes,
}) => (
    <div
        key={item.id}
        className={s.selectItem}
        onClick={() => onClickItem(item.id)}
        role="presentation"
        data-atst={`selectItem-${itemTypes}`}
    >
        {item.id === activeId && (
            <Svg
                className={s.selectIcon}
                name="icon-tick"
            />
        )}
        {item.text}
    </div>
);

const Select: React.FC<IListProps> = ({
    data,
    activeId,
    onClickItem,
    itemTypes = 'default',
}) => (
    <div className={cn(s.select)}>
        {data.map(item => Item({
            item,
            activeId,
            onClickItem,
            itemTypes,
        }))}
    </div>
);

export default Select;
