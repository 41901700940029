import React from 'react';
import Svg from '@components/Svg';
import { NoIndex } from '@components/NoIndex';
import { eventsTop100 } from '@tools/events';

import s from './Statistics.pcss';

interface IStatisticProps {
    title: string;
    titleUrl: string;
    favicon?: string;
    id: number;
    onClick: Function;
}

const getStatUrl = id => `https://stat.top100.rambler.ru/projects/${id}/new/report/rating/`;

const Statistic: React.FC<IStatisticProps> = ({
    title,
    titleUrl,
    favicon,
    id,
    onClick,
}) => {
    const handleClick = () => {
        onClick();
    };
    return (
        <div className={s.root} onClick={handleClick} role="presentation">
            <div className={s.head}>
                <a
                    className={s.headLink}
                    href={titleUrl}
                    target="_blank"
                    rel="noopener nofollow"
                    data-category="clickOnSiteName"
                    data-catalogue={id}
                >
                    {favicon ? (
                        <img
                            className={s.icon}
                            src={`data:image/png;base64,${favicon}`}
                            alt={title}
                        />
                    ) : (
                        <Svg className={s.icon} name="icon-no-favicon" />
                    )}
                    {title}
                </a>
            </div>
            <div className={s.content}>
                <NoIndex>
                    <a
                        className={s.contentLink}
                        href={getStatUrl(id)}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={eventsTop100.sendCVTabLinkStatistic}
                        data-category="clickOnSiteUrl"
                    >
                        {`Статистика по ${titleUrl}`}
                    </a>
                </NoIndex>
            </div>
        </div>
    );
};

Statistic.defaultProps = {
    title: 'Заголовок',
    titleUrl: '',
    favicon: '',
    id: 0,
};

export default Statistic;
