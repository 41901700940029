import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QueryString } from '@tools/queryString';
import DocumentTitle from 'react-document-title';
import cn from 'classnames';

import Layout from '@components/Layout';
import { fetchSideBlock } from '@redux/modules/sideBlock';
import { getAdConfig } from '@tools/ad';
import Ad from '@components/Ad';
import Category from '@components/Category';
import { WorldNarrow } from '@components/Tails';
import Grid from '@components/Grid';
import { Tabs, Tab } from '@components/Tabs';
import Statistic from '@components/Statistic';
import ShowMore from '@components/ShowMore';
import { Modal } from '@components/Modal';
import { RegionsDesktop } from '@components/Regions';
import AnaliticsBanner from '@components/AnaliticsBanner';
import { SchemaOrg } from '@components/SchemaOrg';
import { IBanner, homeAds, banners } from '@configs/ad';
import { customSort } from '@tools/sort';
import { ID_MIRTESEN, SEPARATOR_TOP_THEMES } from '@constants/global';
import { eventsYm } from '@tools/events';
import openstats from '@constants/openstats';
import { getDefaultDocumentTitle } from '@tools/documentTitle';
import { defaultTexts } from '@constants/defaultTexts';

import s from './Home.pcss';

interface IRenderCategoryOptions {
    needIcon: boolean;
    modify: boolean;
    insertAds?: boolean;
}

interface IProps {
    ads: Record<string, IBanner>;
    topicIds: number[];
    topLevelTopicIds: number[];
    category: { [id: number]: ICategoryType };
    region: { [id: number]: IRegionType };
    popularSites: ISites[];
    newSites: ISites[];
    topLevelRegion: number[];
    selectedRegions: number[];
    countsMap: { [id: number]: number };
    getSideBlock: Function;
    getTotals: Function;
    history: any;
    fetchSideBlock: Function;
    fetchTotals: Function;
    requestSideBlock: boolean;
    currentRegionId: number;
    isMobile: boolean;
}

interface IState {
    isOpenModal: boolean;
    currentRegionId: number;
    selectedRegions: number[];
}

export class Home extends PureComponent<IProps, IState> {
    constructor(props) {
        super(props);
        const { currentRegionId } = this.props;

        this.state = {
            isOpenModal: false,
            currentRegionId,
            selectedRegions: this.getSelectedRegions(currentRegionId, [currentRegionId]),
        };
    }

    getSelectedRegions = (id, acc = []) => {
        const { region } = this.props;
        const currentRegion = region[id];

        if (!currentRegion) {
            return [];
        }

        if (!currentRegion.parent_id) {
            return acc.reverse();
        }

        return this.getSelectedRegions(
            currentRegion.parent_id,
            acc.concat([currentRegion.parent_id]),
        );
    };

    getLinkUrl = (base: string = '', ...params) => {
        if (!params.length) {
            return `/${base}/`
                + `${openstats.name}`
                + `${openstats.service.catalogue}`
                + `${openstats.campaign.sberAds}`
                + `${openstats.ad.ratingButtonAllThemes}`
                + `${openstats.source.catalogueHome}`;
        }

        const queryParams = params.map(param => param.join('=')).join('&');
        return `/${base}?${queryParams}`;
    };

    getChildrenLinks = (theme) => {
        const links = theme.children
            .filter(childLink => childLink.show_on_main_page)
            .sort((a, b) => (customSort(a.theme_id, b.theme_id)));

        const newLinks = links.map(link => ({
            id: link.id,
            title: link.title,
            url: this.getLinkUrl('navi', ['categoryId', theme.theme_id], ['subcategoryId', link.theme_id]),
            dataCategory: link.title,
        }));

        newLinks.push({
            id: theme.children.length,
            title: 'Еще',
            url: `${this.getLinkUrl('navi', ['categoryId', theme.theme_id], ['isCatOpen', 1])}`,
            dataCategory: 'other',
        });

        return newLinks;
    };

    renderCategory(items, options: IRenderCategoryOptions) {
        const { category, ads } = this.props;
        const { needIcon, modify, insertAds } = options;

        return (
            items.map((theme, index) => {
                const adBanner = ads[index];
                let ad = null;

                if (insertAds && adBanner) {
                    ad = (
                        <section className={s.banner} key={adBanner.id}>
                            <Ad
                                isLazy
                                canUpdate
                                {...adBanner}
                            />
                        </section>
                    );
                }

                return (
                    <React.Fragment key={category[theme].id}>
                        {ad}
                        {theme === ID_MIRTESEN
                            ? (
                                <WorldNarrow
                                    key={category[theme].id}
                                    url={this.getLinkUrl('navi', ['categoryId', category[theme].theme_id])}
                                />
                            )
                            : (
                                <Category
                                    dataCategory="largeCategories"
                                    className={s.category}
                                    modify={modify}
                                    iconName={needIcon && `icon-${category[theme].id}`}
                                    title={category[theme].title}
                                    titleUrl={this.getLinkUrl('navi', ['categoryId', category[theme].theme_id])}
                                    links={this.getChildrenLinks(category[theme])}
                                />
                            )
                        }
                    </React.Fragment>
                );
            })
        );
    }

    toggleOpenModal = (isOpen) => {
        this.setState({
            isOpenModal: isOpen,
        });
    }

    onApplyRegion = async (regions) => {
        const { getSideBlock, history } = this.props;
        const regionId = regions[regions.length - 1] || 0;

        this.setState({
            currentRegionId: regionId,
            selectedRegions: regions,
        });

        const queryParams = QueryString.getString({ regionId });

        history.push({ search: queryParams });

        document.cookie = `reg_id=${regionId}`;

        await getSideBlock(regionId);
    }

    render() {
        const {
            topLevelTopicIds,
            topicIds,
            newSites,
            popularSites,
            region,
            countsMap,
            topLevelRegion,
            requestSideBlock,
            isMobile,
        } = this.props;

        const { isOpenModal, currentRegionId, selectedRegions } = this.state;

        const topTopic = topLevelTopicIds.slice(0, SEPARATOR_TOP_THEMES);
        const restTopTopic = topLevelTopicIds.slice(SEPARATOR_TOP_THEMES);

        const currentRegion = region[currentRegionId]
            ? region[currentRegionId].title
            : defaultTexts.regions;

        const documentTitle = getDefaultDocumentTitle();

        return (
            <DocumentTitle title={documentTitle}>
                <Layout>
                    <SchemaOrg title={documentTitle} />
                    {!isMobile && (
                        <AnaliticsBanner />
                    )}
                    <Grid
                        paramsColumns={[
                            { size: '1_2', className: s.topTopic },
                            { size: '1_2' },
                        ]}
                    >
                        <>
                            {
                                topTopic.length > 0 && this.renderCategory(topTopic, {
                                    needIcon: true,
                                    modify: false,
                                })
                            }
                        </>
                        <div className={s.tabs} data-category="regional">
                            <Tabs
                                request={requestSideBlock}
                                region={currentRegion}
                                onClick={() => this.toggleOpenModal(true)}
                            >
                                <Tab
                                    titleTab="Популярные"
                                    showMore={<ShowMore url={`/navi?regionId=${currentRegionId}`} />}
                                    name="tab-popularSites"
                                >
                                    {popularSites.map(site => (
                                        <Statistic
                                            id={site.id}
                                            favicon={site.favicon}
                                            title={site.name}
                                            titleUrl={site.url}
                                            key={site.id}
                                            onClick={eventsYm.clickBlockPopularLink}
                                        />
                                    ))}
                                </Tab>
                                <Tab
                                    titleTab="Новички"
                                    name="tab-newSites"
                                >
                                    {newSites.map(site => (
                                        <Statistic
                                            id={site.id}
                                            favicon={site.favicon}
                                            title={site.name}
                                            titleUrl={site.url}
                                            key={site.id}
                                            onClick={eventsYm.clickBlockNewLink}
                                        />
                                    ))}
                                </Tab>
                            </Tabs>
                        </div>
                    </Grid>

                    {!isMobile &&(
                        <section
                            className={cn(s.banner, 'ads-block')}
                            key={banners.superFooter.id}
                        >
                            <Ad
                                isLazy
                                canUpdate
                                {...banners.superFooter}
                            />
                        </section>
                    )}
                    <Grid paramsColumns={[{ size: '1_2' }, { size: '1_2' }]}>
                        {restTopTopic.length > 0 && this.renderCategory(restTopTopic, {
                            needIcon: true,
                            modify: false,
                        })}
                    </Grid>

                    {topicIds.length > 0 && this.renderCategory(topicIds, {
                        needIcon: false,
                        modify: true,
                        insertAds: true,
                    })}

                    <Link
                        to={this.getLinkUrl('navi')}
                        className={s.rating}
                        children="Рейтинг по всем темам"
                    />

                    <Modal
                        isOpened={isOpenModal}
                        toggleOpenModal={this.toggleOpenModal}
                    >
                        <RegionsDesktop
                            isShowCount={false}
                            countsMap={countsMap}
                            dict={region}
                            firstItems={topLevelRegion}
                            selected={selectedRegions}
                            onApply={(selected) => {
                                this.toggleOpenModal(false);
                                this.onApplyRegion(selected);
                            }}
                            onCancel={() => this.toggleOpenModal(false)}
                        />
                    </Modal>
                </Layout>
            </DocumentTitle>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getSideBlock: (regionId: number) => dispatch(fetchSideBlock(regionId)),
});

const mapStateToProps = state => ({
    countsMap: state.filters.regions,
    topLevelRegion: state.region.topLevelRegion,
    ads: getAdConfig(homeAds, state.runtime.isMobile),
    category: state.category.data,
    topicIds: state.category.topicIds,
    topLevelTopicIds: state.category.topLevelTopicIds,
    currentRegionId: state.runtime.regionId,
    newSites: state.sideBlock.newSites,
    popularSites: state.sideBlock.popularSites,
    region: state.region.data,
    filters: state.filters,
    requestSideBlock: state.sideBlock.request,
    isMobile: state.runtime.isMobile,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
