import React, { useState, useEffect } from 'react';
import Svg from '@components/Svg';
import Button from 'rambler-ui/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCleanPathname } from '@tools/pathname';

import s from './CancelSubscription.pcss';

export const CancelSubscription: React.FC<RouteComponentProps> = ({
    history: { location: { pathname } },
}) => {
    const isAuth = useSelector(({ user }) => user.isAuth);
    const isMobile = useSelector(({ runtime }) => runtime.isMobile);
    const hasSubscription = useSelector(({ runtime }): number => runtime.hasPrimeSubscription);

    const [locationHref, setLocationHref] = useState('');

    useEffect(() => {
        setLocationHref(encodeURIComponent(window.location.href));
    }, []);

    const cleanPathname = getCleanPathname(pathname);
    const projectName = 'top100';
    const dataCerber = `top100::${isMobile ? 'mobile' : 'desktop'}::${cleanPathname}::reklama_on_block::reklama_on_button`;
    const href = `https://id.rambler.ru/account/subscriptions/?utm_source=${projectName}&utm_medium=${locationHref}&utm_campaign=reklama_on&utm_content=id&utm_term=${isMobile ? 'mob' : 'desk'}`;

    if (pathname !== '/' || isMobile || !hasSubscription || !isAuth) return null;

    return (
        <div className={s.cancelSubscription}>
            <div className={s.cancelSubscriptionTitle}>
                <Svg name="icon-cancel-subscribe" className={s.cancelSubscriptionIcon} />
                Отмена подписки
            </div>
            <div className={s.cancelSubscriptionDescr}>
                Мы больше не будем списывать плату за подписку
            </div>
            <Button
                size="small"
                href={href}
                data-cerber={dataCerber}
            >
                Включить рекламу
            </Button>
        </div>
    );
};

export default withRouter(CancelSubscription);
