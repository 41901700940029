import React from 'react';

import s from './Loader.pcss';

export const Loader: React.FC = () => (
    <span className={s.root}>
        <span className={s.dot} />
        <span className={s.dot} />
        <span className={s.dot} />
    </span>
);
