import React, { useState } from 'react';
import { Select } from '@components/Select';

import s from './Regions.pcss';

interface IRegionsProps {
    dict: { [id: number]: IRegionType };
    firstItems: number[];
    selected: number[];
    onChange: Function;
}

const RegionsMobile: React.FC<IRegionsProps> = ({
    firstItems, dict, selected: propsSelected, onChange,
}) => {
    const [selected, setSelected] = useState(propsSelected);

    const handleSelectChange = (id: number, currentSelected: number[] = []) => {
        const result = id ? [...currentSelected, id] : [...currentSelected];
        setSelected(result);

        onChange(id);
    };

    const prepareItems = (items: IRegionType[]) => (items.map(item => ({
        id: item.id,
        text: item.title,
    })));

    const renderItem = (id: number, index: number, currentSelected: number[] = []) => {
        let items = dict[id] ? prepareItems(dict[id].children) : [];

        if (items.length === 0) {
            return null;
        }

        items = [
            {
                id: 0,
                text: 'Уточнить географию',
            },
            ...items,
        ];

        return (
            <Select
                key={index}
                data={items}
                activeId={selected[index + 1] || 0}
                selectClassName={s[`children_${index + 1}`]}
                onChange={
                    changeId => handleSelectChange(Number(changeId), currentSelected)
                }
            />
        );
    };

    const items = [
        {
            id: 0,
            text: 'География',
        },
        ...prepareItems(firstItems.map(id => dict[id])),
    ];

    return (
        <>
            <Select
                data={items}
                activeId={selected.length ? selected[0] : 0}
                onChange={id => handleSelectChange(Number(id))}
            />
            {selected.length > 0 && selected.map((id, index) => renderItem(
                id,
                index,
                selected.filter((id, i) => i <= index), // eslint-disable-line
            ))}
        </>
    );
};

export default RegionsMobile;
