import React, { MouseEvent } from 'react';
import cn from 'classnames';
import Svg from '@components/Svg';

import s from './Pagination.pcss';

interface ILinkIconProps {
    name: string;
    isPrev?: boolean;
    isDisabled?: boolean;
    onClick: (event: MouseEvent) => void;
    dataAttributes?: Record<string, string>;
}

export const BtnIcon: React.FC<ILinkIconProps> = ({
    name = '',
    isPrev = false,
    isDisabled = false,
    onClick,
    dataAttributes = {},
}) => {
    const cnBtn = cn(s.btnIcon, {
        [s.isDisabled]: isDisabled,
    });
    const cnSvg = cn(s.icon, {
        [s.iconPrev]: isPrev,
    });
    return (
        <button
            className={cnBtn}
            onClick={onClick}
            type="button"
            {...dataAttributes.name ? { [dataAttributes.name]: dataAttributes.value } : {}}
        >
            <Svg className={cnSvg} name={name} />
        </button>
    );
};
