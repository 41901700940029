import React from 'react';
import cn from 'classnames';
import Svg from '@components/Svg';
import { Link } from 'react-router-dom';
import { eventsTop100 } from '@tools/events';

import s from './Category.pcss';

interface ILinksProps {
    id: number;
    title: string;
    url: string;
    dataCategory: string;
}

interface ICategoryProps {
    className?: string;
    titleUrl: string;
    iconName?: string;
    title: string;
    modify?: boolean;
    links: ILinksProps[];
    dataCategory?: string;
}

const Category: React.FC<ICategoryProps> = ({
    className = '',
    titleUrl = '',
    iconName = '',
    title = 'Имя ссылки',
    links = [],
    modify = false,
    dataCategory = '',
}) => {
    const cnRoot = cn(s.root, className, {
        [s.rootLong]: modify,
    });


    /*
        Пробелы вокруг Link внутри списка не удалять, они нужны для правильного отображения ссылок
    */
    return (
        <section className={cnRoot} data-category={dataCategory}>
            <h2 className={s.title}>
                <Link
                    className={s.link}
                    to={titleUrl}
                    data-category={title}
                    onClick={() => eventsTop100.sendCVOpenRatingCategory(title)}
                >
                    {iconName && <Svg className={s.icon} name={iconName} />}
                    {title}
                </Link>
            </h2>
            <ul className={s.list}>
                {links.map(link => (
                    <li className={s.listItem} key={link.id}>
                        {' '}
                        <Link
                            className={s.contentLink}
                            to={link.url}
                            children={link.title}
                            data-category={link.dataCategory}
                        />
                        {' '}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default Category;
